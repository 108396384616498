.product{
    .fresult{   
        margin-bottom: 1.5em;     
        span{
            @include font-size(pxToEm(16),pxToEm(18));
            @include font-color(#777,400,1.5);
            display: inline-block;
            margin-right: 30px;
        }
        span:before{
            content:"";
            display: inline-block;
            width: 1em;
            height: 1em;
            background-color: $color1;
            margin-right: 16px;
            vertical-align: -2px;
        }
        i{
            @include font-size(pxToEm(16),pxToEm(18));
            @include font-color(#777,400,1.5);
        }
        i::before{
            content:" | ";
            margin-right: 16px;
        }
        @include mediaquery(null,$lg){
            div{
                @include font-size(pxToEm(20),pxToEm(22));
                padding-left: 2.3em;
                margin-top: 14px;
            }
        }
        @include mediaquery($lg,null){
            display: flex;
            h5{
                flex:0 0 150px;
            }
            div{                
                padding-left: 60px;
                flex:1;
            }
            span{
                margin-top: 8px;
            }
        }
    }
    h5{
        @include font-color($color1,400,1);
        @include font-size(pxToEm(20),pxToEm(22));
        img{
            width: 1.5em;
            margin-right: 0.8em;
            vertical-align: -0.1em;
        }
    }   
    #searchbox{
        width: 100%;
        height: 1px;
        @include mediaquery(null,$md){
            transform: translateY(-130px);
        }
        @include mediaquery($md,$lg){
            transform: translateY(-220px);
        }
        @include mediaquery($lg,null){
            transform: translateY(-180px);
        }
    } 
}
.product .row .formtable{    
    .mtitle{
        @include font-color(#101010,400,1);
        @include font-size(pxToEm(19),pxToEm(20));
        border-bottom: 3px solid #e7f7ff;
        padding:0.8em 0;
        margin-bottom: 1em;
        @include mediaquery(null,$sm){
            border-bottom-width: 2px;
            margin-bottom: 0.8em;
        }
    }
    .check_radi{
        @include mediaquery(null,$sm){
            padding:4px 1em 4px 2em;
            margin-bottom: 0.4em;
        }
        @include mediaquery($sm,null){
            padding-left: 2em;
            margin-bottom:0.2em ;
        }
    }
    .catbtn{       
        i{
            width: 0.8em;
            height: 0.8em;
            @include position(absolute,$top:50%,$right:0);
            transform: translateY(-50%);
        } 
        i:before,i:after{
            content:"";
            background-color: white;
        }
        i:before{
            width: 2px;
            height: 0.8em;
            @include position(absolute,$left:calc(0.4em - 1px),$top:0);
        }
        i:after{
            width: 0.8em;
            height: 2px;
            @include position(absolute,$left:0,$top:calc(0.4em - 1px));
            opacity: 1;
            transition: opacity 0.6s;
        }
        &.is-open i:before{
            opacity: 0;
        }
        @include font-color(white,400,1);
        @include font-size(pxToEm(18),pxToEm(20));
        @extend .bg-grad; 
        cursor: pointer;
        @include mediaquery(null,$sm){
            padding:14px 16px;
            margin: 24px 0;
        }
        @include mediaquery($sm,$lg){
            width: 50%;
            padding:14px 16px;
            margin: 24px 0;
        }
        @include mediaquery($lg,null){
            max-width: 315px;
            flex:0 0 45%;
            padding:24px 30px;
        }
    }
    .searchbx{
        position: relative;
        input{
            background-color: white;
            border-bottom: 1px solid #ccc;
            padding:0.7em 0;
        }
        @include mediaquery($md,null){
            max-width: 310px;
            flex:0 0 45%;
        }
    }
    .btngroup{
        a{
            text-align: center;
            display: inline-block;   
            @include font-size(pxToEm(15),pxToEm(18));
        }
        .sedbtn{            
            @include font-color(white,400,1.6);            
            @extend .bg-grad; 
        }
        .clerbtn{
            @include font-color($color1,400,1.6);
            background-color: white;
            border:1px solid $color1;
        }
        @include mediaquery(null,$md){
            display: flex;
            justify-content: space-between;
            margin: 30px 0;
            a{
                flex:0 0 47%;
                padding:0.5em 0 0.4em;
            }
        }
        @include mediaquery($md,null){
            text-align: right;
            margin-top: 48px;
            margin-bottom: 5px;
            a{
                width: 176px;
                margin-left: 20px;
                padding:0.7em 0 0.6em;
            }
        }
    }
    .w100{
        flex: 0 0 100%;
    }
    .chexbx{        
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        @include mediaquery(null,$sm){            
            margin-bottom: 16px;
            line-height: 1.5;
            &.v1 > div{
                flex:0 0 100%;
            }
            &.v2 > div:not(.w100){
                flex:0 0 50%;
            }
        }
        @include mediaquery($sm,$lg){
            > div:not(.w100){
                flex:0 0 33.33%;
            }
        }
        @include mediaquery($lg,null){
            margin-top: 40px;
            &.v1{
                flex:0 0 20%;
            }
            &.v2{
                flex:0 0 76%;
            }
            &.v2 > div:not(.w100){
                flex:0 0 25%;
            }
        }
    }       
    .keybx{
        @include mediaquery($md,null){            
            display: flex;
            align-items: center;
            justify-content: space-between;
            .searchbx{
                order: 2;
            }
            .catbtn{
                order: 1;
            }
        }
    }
    background-color: white;
    box-shadow: 0 0 30px 0 rgba(160, 160, 160, 0.3);    
    @include font-color(#777,300,1.85);
    font-size: pxToEm(16);
    @include mediaquery(null,$sm){
        padding:20px 24px;
        margin-bottom: 60px;
        // margin-top: -115px;
    }
    @include mediaquery($sm,$lg){
        padding:30px 30px 30px;
        margin-bottom: 50px;
        // margin-top: -70px;
    }
    @include mediaquery($lg,null){
        padding:40px 60px;
        margin-bottom: 70px;
        // margin-top: -12vw; 
        .hdtable{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;  
        }  
    }
}
.product .row-table{    
    @include font-color(#101010,300,1.75);
    @include font-size(pxToEm(15),pxToEm(16));
    @include mediaquery(null,$lg){
        .list{
            margin-bottom: 40px;
        }
        .list > div{
            display: table;
            width: 100%;
            margin-bottom: 4px;            
        }
        .list > div:nth-child(odd){
            background-color: #e9f8ff;
        }
        .list span{
            vertical-align: middle;
            display: table-cell;
            padding:12px 20px;
        }
        .list > div:before{
            background-color: $color1;
            color: white;
            text-align: center;
            vertical-align: middle;
            display: table-cell;
        }
        .list > div:nth-child(1):before{
            content:"產品名稱";
        }
        .list > div:nth-child(2):before{
            content:"INCI NAME";
        }
        .list > div:nth-child(3):before{
            content:"效能";
        }
        .list > div:nth-child(4):before{
            content:"備註";
        }
        .list > div:nth-child(5):before{
            content:"包裝";
        }
        .list.mtitle{
            display: none;
        }
    }
    @include mediaquery(null,$sm){
        margin-bottom: 35px;
        .list span{
            padding:3px 14px;
        }
        .list > div:before{
            padding:3px;
            width: 7em;
        }
    }
    @include mediaquery($sm,$lg){
        margin-bottom: 70px;
        .list span{
            padding:12px 20px;
        }
        .list > div:before{
            padding:10px;
            width: 10em;
        }
    }
    @include mediaquery($lg,null){
        margin-bottom: 70px;
        .list{
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d2d2d2;
        }
        .list > div{
            border-left:2px solid white;
            border-right:2px solid white;
        }
        .list > div:nth-child(1){
            flex:0 0 20%;
            color:$color1;
            span{
                width: 10em;
                display: block;
            }
        }
        .list > div:nth-child(2){
            flex:0 0 20%;
        }
        .list > div:nth-child(3){
            flex: 0 0 37%;
        }
        .list > div:nth-child(4){
            flex:0 0 15%;
        }
        .list > div:nth-child(5){
            flex: 0 0 8%;
            text-align: center;
        }
        .list > div:nth-child(1),
        .list > div:nth-child(2){
            padding:24px 16px;
        }
        .list > div:nth-child(3),
        .list > div:nth-child(4){
            padding:24px 16px;
        }
        // .list > div:nth-child(4),
        // .list > div:nth-child(5){
        //     padding:24px;
        // }
        .list.mtitle > div{
            background-color: $color1;
            color: white;
            text-align: center;
            padding:9px;
        }
    }
}