.formtable{   
    input,select,textarea{
        background:#e7f7ff;
        outline: none;
        appearance: none;
        box-shadow:none;
        border: none;
        width:100%;
        @include border(0);
        &:focus { outline: none; }
        font-family: $mfont;
        @include font-color(black,300,1.5);
        @include font-size(pxToEm(14),pxToEm(16));
        vertical-align: middle;  
    }
    @include placeholder {
        color:#777;
        font-weight: 300;
        font-family: $mfont;
    }  
    @include mediaquery(null,$md){
        input,select,textarea{
            padding:0.8em 1em 0.6em;
        }    
        label{
            padding: 0.2em 0;
        }
    }
    @include mediaquery($md,null){
        input,select,textarea{
            padding:0.8em 1.1em .6em;
        }  
        label{
            padding: 0.8em 0;
        }
    }    
    label{
        position: relative;
        display: block;
        @include font-size(pxToEm(16),pxToEm(18));
        @include font-color(black,400,1.5);
    }
    label.requ:before{
        content:"*";
        color:$color1;
        vertical-align: 4px;
    }
    .with-errors{
        @include font-size(pxToEm(12),pxToEm(14));
        @include font-color($color1,400,1.5);
        display:block;
        max-width: 100%;
        min-height:1.5em;
        margin-top: 8px;
        margin-bottom: 1.6em;
    }
    .srhbtn{
        cursor: pointer;
        background: no-repeat url(../svg/search.svg) center;
        @include position(absolute,$top:50%,$right:0px);
        transform: translateY(-50%);
        width:40px;
        height: 40px;
        @include mediaquery(null,$sm){
            background-size: 22px;
        }
        @include mediaquery($sm,null){
            background-size: 26px;
        }
    }
    .check_radi {
        width: auto;
        position: relative;
        cursor: pointer;
        user-select: none;
        input[type="checkbox"],input[type="radio"]{
            @include position(absolute,$top:0,$left:0);
            opacity: 0;
            cursor: pointer;
            z-index: 5;
            width: 100%;
            height: 100%;
            margin:0;
        }
        .boxmark {
            @include position(absolute,$top:50%,$left:50%);
            height: 20px;
            width: 20px;
            border:1px solid #777;
            @include border();
            @include mediaquery(null,$sm){
                transform: translate(-50%,-50%) scale(0.7);
                transform-origin: left;
            }
            @include  mediaquery($sm,null){
                transform: translate(-50%,-50%);
            }
        }
        .boxmark:after {
            content: "";
            @include position(absolute,$top:50%,$left:50%);
            transform: translate(-50%,-50%);
            display: none;
            width: 10px;
            height: 10px;
            background: $color1;
            @include border();
        }
        input:checked~.boxmark:after {
            display: block;
        }
        .checkmark {
            @include position(absolute,$top:0.3em,$left:0);
            height: 20px;
            width: 20px;
            background-color: #e9f8ff;
            @include mediaquery(null,$sm){
                top:calc(0.3em + 1px);
            }
        }
        input:checked~.checkmark{
            background-color: $color1;
        }
        // .checkmark:after {
        //     content: "";
        //     @include position(absolute,$top:1px,$left:3px);
        //     display: none;
        //     width: 20px;
        //     height: 20px;
        //     background: $color1;
        // }
        // input:checked~.checkmark:after {
        //     display: block;
        // }
    } 
}
.formtable.col-2{
    .colum2{
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        @include mediaquery(null,$lg){
            .form-group{
                flex:0 0 calc(50% - 12px);
                width:calc(50% - 12px);
            }
        }
    }
    @include mediaquery($lg,null){
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;        
        .form-group:not(.w100){
            flex:0 0 calc(50% - 25px);
            width:calc(50% - 25px);
        }
        .form-group.w100,.w100{
            flex:0 0 100%;
            width:100%;
        }
    }
}
.contact .form-row{
    background-color: white;
    box-shadow: 0 0 30px 0 rgba(160, 160, 160, 0.3);
    .mtitle{
        @include font-color($color1,400,1.33);
        @include font-size(pxToEm(18),pxToEm(30));
        border-bottom: 1px solid #d2d2d2;
        @include mediaquery(null,$sm){
            padding:20px 0 24px;
            margin-bottom: 30px;
        }
        @include mediaquery($sm,null){
            padding: 30px 0 36px;
            margin-bottom: 40px;
        }
        @include mediaquery($sm,$lg){
            font-size: pxToEm(24);
        }
        @include mediaquery($lg,null){
            br{
                display: none;
            }
        }
    }
    .btn{
        @include mediaquery(null,$sm){
            margin:12px auto 0;
        }
        @include mediaquery($sm,null){
            margin:40px auto 0;
        }
    }
    @include mediaquery(null,$sm){
        width:calc(100% - 40px);
        padding-bottom: 60px;
        padding-top: 40px;
        margin-bottom: 80px;
        img{
            width:50px;
        }
    }
    @include mediaquery($sm,$lg){
        padding:30px 30px 45px;
        margin-bottom: 80px;
    }
    @include mediaquery($lg,null){
        padding:60px 100px 85px;
        margin-bottom: 120px;
        textarea{
            height: 12.4em;
        }
    }
}
.contact .btngrup{
    &::before{
        content:"";
        height: 100%;
        background-color: $color1;
        @include position(absolute,$left:0,$top:0);
        z-index: -1;
        @include mediaquery(null,$sm){
            width:calc(100% - 20px);
            left:20px;
        }
        @media screen and (max-width:360px){
            width: 100%;
            left:0;
        }
        @include mediaquery($sm,$lg){
            width: 150%;
        }
        @include mediaquery($lg,null){            
            width:110%;
        }
    }
    a{
        @include font-color(white,400,1.38);
        @include font-size(pxToEm(15),pxToEm(22));
        display: inline-block;
        position: relative;
        z-index: 10;
        text-align: center;
        opacity: 0.7;        
        @extend .line_hover;
        @include mediaquery(null,$sm){ 
            padding:0 0 0.1em;
            margin:0 0.3em; 
        }
        @include mediaquery($sm,null){   
            padding:0 .2em 0.9em;
            margin:0 0.5em; 
        }
    }
    a:after{
        background-color: white;
        @include mediaquery(null,$sm){ 
            height: 3px;
        }
        @include mediaquery($sm,null){   
           height: 5px;
        }
    }
    a.active{
        opacity: 1;
        &:after{                    
            width: 100%;
        }
    }
    @include mediaquery(null,$sm){
        padding:20px 0px 30px;
    }
    @include mediaquery($sm,null){     
        padding-top:40px;
        padding-bottom: 100px;
        padding:40px 0 100px 0;
        a:hover{
            opacity: 1;
            &:after{                    
                width: 100%;
            }
        }
    }
}
.contact .row.bggray{
    @include mediaquery(null,$lg){
        background-color: #f5f4f4;
    }
}
.contact .infobx{
    background-color: #f5f4f4;
    position: relative;
    &:before{
        content:"";
        height: 100%;
        background: no-repeat url(../images/contact_bg.jpg) #f5f4f4 left top / 832px 649px;
        @include mediaquery(null,$sm){
            display: none;
        }
        @include mediaquery($sm,$lg){
            @include position(absolute,$left:-70vw,$top:0);
            width:832px;
        }
        @include mediaquery($lg,null){
            @include position(absolute,$right:720px,$top:0);
            width:832px;
        }
    }
    .mitem{
        position: relative;
        z-index: 10;
    }
    .aprt{
        @include font-color($color1,500,1.38);
        @include font-size(pxToEm(18),pxToEm(24));
    }
    .list{    
        span{
            @include font-color(#101010,300,1.57);
            @include font-size(pxToEm(14),pxToEm(16));
        }
        a{
            background: no-repeat url(../images/icon/icon_mail.png) center left / 24px 1em;
            @include font-color($color1,300,1.57);
            @include font-size(pxToEm(14),pxToEm(16));
            padding-left: 40px;
        }
        @include mediaquery(null,$sm){
            > div:not(:last-child){
                margin-bottom: 16px;
            }
            span{
                display: block;
                margin-bottom: 0.2em;
            }
            a{
                background-size: 16px;
                padding-left: 28px;
            }
        }
        @include mediaquery($sm,null){
            > div{
                display: flex;
            }
            > div:not(:last-child){
                margin-bottom: 0.8em;
            }
            span{
                flex:0 0 10em;
            }
            a{
                flex:1;
            }
        }
    }
    .mitem:not(:last-child){        
        border-bottom: 1px solid #d2d2d2;    
    }
    @include mediaquery(null,$sm){
        .aprt{
            padding:20px 16px;
        }
        .list{
            padding:0 16px 30px;
        }
    }
    @include mediaquery($sm,$lg){
        margin-top: -60px;
        .mitem{
            padding:20px 0;
        }
        .aprt{
            padding:12px 20px;
        }
        .list{
            padding:0 20px;
        }
    }
    @include mediaquery($lg,null){
        padding: 40px 0;
        margin-top: -60px;
        .mitem{
            display: flex;
            align-items: center;
            margin-left: 30%;
            width: 65%;
            padding:40px 0;
        }
        .aprt{
            flex:0 0 180px;
        }
        .list{
            flex:1;
        }
    }
}
//other overwrite
// .contact{    
//     @include mediaquery(null,$lg){
//         .pgkv{
//             .row{
//                 bottom:-50px;
//             }
//             .bgcolor{
//                 height: 120px;
//             }
//             .titlebx{
//                 margin-top: -18px;
//             }
//         }
//     }
//     @include mediaquery($lg,null){
//         .pgkv{
//             .bgcolor{
//                 height: 13vw;
//             }
//             .titlebx{
//                 margin-top: -2vw;
//             }
//         }
//     }
// }