/*----------------------通用設定------------------------*/
//selection
p,h1,h2,h3,h4,b,strong,span,li,div,a,img{
  &::selection{
    color:#fff;
    background:#000;
  }
}
//a tag
a{outline: none;}
a,a:hover {
  text-decoration: none;
  transition: 0.4s;
  color:initial;
}
*:focus {
    outline: none;
}
img {
    vertical-align: middle;
    max-width: 100%;
}

/*----------------------共用樣式------------------------*/
.text-center{text-align: center;}
.text-right{text-align:right;}
.text-justify{text-align: justify;}
.mpsrel{position: relative;}
.bgcover{
  width:100%;
  height:0;
  background: center / cover;
}
.col-temp-3{
  @include mediaquery($md,null){
    display: flex;
    flex-wrap: wrap; 
    justify-content: flex-start;
    margin-left:-$pdr;
    margin-right:-$pdr;
    > div{
      max-width:calc((100% - 6em) / 3);
      flex:0 0 calc((100% - 6em) / 3);
      margin:1em;
    }
  }
}
.col-temp-2{
  @include mediaquery($md,null){
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left:-$pdr;
    margin-right:-$pdr;
    > div{
      max-width:calc(50% - 2em);
      flex:0 0 calc(50% - 2em);
      margin:1em;
    }
  }
}
/*----------------------編輯器------------------------*/
.editor_inner{  
  @include font-size(pxToEm(14),pxToEm(16));
  @include font-color(black,300,1.75);
  ul,ol {
    padding-left: 10px;
  }
  ol{
    list-style: decimal;
  }
  ul{
    list-style: disc;
  }
  img {
      max-width: 100%;
      height: auto !important;
  }
  b,strong{
    font-weight: bold;
    font-style: inherit;
  }
  em{
  font-style: italic; 
  }
  a{
    text-decoration: underline;
    color:#0782C1;
  }
  iframe {
      max-width: 100%;
  }
  h1,h2,h3,h4,h5,h6 {
      margin: inherit;
  }
  blockquote{
    font-style: italic;
    padding: 2px 20px 0 8px;
    border-left:5px solid #ccc;
    margin-left: 40px;
  }
  @include mediaquery(null,$sm){
    line-height: 1.64;
  }
  @include mediaquery(null,$md){
    &.show-pc{
      display: none;
    }
    + .back{
      margin-top: 50px;
    }
  }
  @include mediaquery($md,null){
    &.show-mo{
      display: none;
    }
    + .back{
      margin-top: 100px;
    }
  }
}
/*-------------------背景圖片 桌手機分開-----------------*/
@include mediaquery(null,$sm){
  .bgcover[data-src-lg]{
    content: attr(data-src-lg, url);
  }
}
@include mediaquery($sm,null){
  .bgcover[data-src-sm]{
    content: attr(data-src-sm, url);
  }
}
/*---------------------video----------------------*/
.embed-responsive{
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  .embed-responsive-item{
    @include position(absolute,$top:0,$left:0,$right:0,$bottom:0);
    width:100%;
    height: 100%;;
  }
  &.embed-responsive-16by9:before{
    content:"";
    display: block;
    padding-top: 56.25%;
  }
}
#topBtn{
  display:block;
  z-index:1;
  opacity: 0;
  visibility: hidden;
  transition: 0s;
  .svg{
    width:40px;
    .st0{
      fill:#777777;
      transition: fill 0.6s;
    }
	  .st1{
      fill:#FFFFFF;
      transform: translateY(0px);
      transition:transform 0.6s;
    }
  }
  //位置轉換
  &.open{
    opacity: 1;
    visibility: visible;
    z-index:99;
  }
  &.change{
    position: absolute;
  }
  @include mediaquery(null,$sm){
    .svg{
      width: 32px;
    }
  }
  @include mediaquery(null,$md){    
    @include position(fixed,$bottom:15px,$right:$pdr-m);
    &.change{
      bottom: -60px;
    }
  }
  @include mediaquery($md,null){
    @include position(fixed,$bottom:20px,$right:$pdr-m);
    &.change{
      bottom: -80px;
    }
    &:hover{
      .svg{
        circle.st0{fill:$color1;}
        path.st0{fill:black;}
        .st1{transform: translateY(-12px);}
      }
    }
  }
  @include mediaquery($xxl,null){
    right:100px;
    bottom:60px;
    &.change{
      bottom: -125px;
    }
  }
}
//arrow
.ltbn,.rtbn{
  position: relative;
  .st0{
    transition: fill 0.6s;
  }
  .st1{
    fill:none;
    stroke-width:12;
    stroke-linecap:round;
    stroke-linejoin:round;
  } 
}
.page{
  @include font-size(pxToEm(16),pxToEm(18));
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  a{
    @include font-color(#666,400,1);
    display: block;
    padding:0 8px;
  }
  .active a{
    color: $color1;
  }
  //svg
  .ltbn a, .rtbn a{
    position: relative;
  }
  .st0{
    fill:#666;
  }
  .st1{
    stroke: #666;
  }
  .nopage .svg,
  .nopage a:hover .svg{
    opacity: 0.2;
    cursor: default;
  }
  @include mediaquery(null,$sm){
    margin-top: 60px;
    dt,dd{
      margin:0 0.5em;
    }
    .svg{
      width:30px;
    }
    .up{
      display: none;
    }
  }
  @include mediaquery($sm,null){
    margin-top: 60px;
    a:hover{
      color:$color1;
    }
    dt{
      margin:0 18px;
    }
    dd{
      margin:0 10px;
    }
    .svg{
      width:30px;
    }
    .up{
      @include position(absolute,$top:0,$left:8px); 
    }    
    .ltbn:not(.nopage) a:hover, .rtbn:not(.nopage) a:hover{
      .down .st0{
        fill:$color1;
      }
      .st1{
        animation:fJOOgbfF_draw cubic-bezier(0.260, 0.005, 0.000, 1.035) 0.4s forwards;
        stroke: $color1;
        stroke-dasharray:483 485;
        stroke-dashoffset:485;
      }
    }
  }
}
@keyframes fJOOgbfF_draw{
  0%{stroke-dashoffset:484;}
  100%{stroke-dashoffset:0;}
}