.indx{
    main{        
        padding-bottom:0;
        overflow: hidden;
    }
    .titlebx{
        display: flex;
        align-items: flex-end;
        h4{
            @include font-color($color1,400,1);
            @include font-size(pxToEm(22),pxToEm(30));
        }
        h4 + span{
            @include font-color(#101010,500,1.2);
            @include font-size(pxToEm(14),pxToEm(18));
            font-family: $fen;
        }
        @include mediaquery(null,$sm){
            padding:0em .2em;
            border-left: 4px solid #101010;
            margin-bottom: 2em;
            h4{
                padding:0 .6em 0 .5em;
            }
        }
        @include mediaquery($sm,null){
            padding:0.35em 0.2em;
            border-left: 0.5em solid #101010;
            margin-bottom: 2.5em;
            h4{
                padding:0 0.9em 0 0.7em;
            }
        }
    }
    .btn{
        @include mediaquery(null,$sm){
            margin-top: 36px;
        }
        @include mediaquery($sm,null){
            margin-top:56px;
        }
    }
    .bxname{        
        @include font-color($color2,100,1);
        letter-spacing: 0;
        @include mediaquery(null,$sm){
            font-size: 100px;
        }
        @include mediaquery($sm,null){
            font-size:13vw;
        }
    }
    .bg1{
        background-color: $color1;
    }
    .bg2{
        background-color: $color2;
    }
    .bg3{
        background-color: #f5f4f4;
    }
}
.indx-banner{
    .txt{        
        @include font-color($color1,700,1);
        @include font-size(pxToEm(12),pxToEm(18));
        @include position(absolute,$right:0,$top:0%);
        writing-mode: vertical-lr ;
        height: 75%;
        text-align: center;        
    }
    a{
        display: block;
        width: 100%;
        overflow: hidden;
    }
    .bgcover{
        transform: scale(1.1);
        transition: transform 5s;
        @include mediaquery(null,$md){
            padding-bottom:139%;
        }
        @include mediaquery($md,null){
            padding-bottom: 41.67%;
        }
    }
    .slickinit .slick-slide.slick-active .bgcover{
        transform: scale(1);
    }
    .bg1{
        z-index: -1;
        @include mediaquery(null,$sm){
            width:80%;
            height: 120px;
            @include position(absolute,$right:0,$bottom:-5px);
        }
        @include mediaquery($sm,$lg){
            width: 56%;
            height: 22vw;
            @include position(absolute,$right:0,$bottom:calc(-11vw + 60px));
        }
        @include mediaquery($lg,null){
            width: 56%;
            height: 13vw;
            @include position(absolute,$right:0,$bottom:calc(-5vw + 60px));
        }
    }
    .custom_paging{
        @include font-color(white,700,1);
        @include font-size(pxToEm(12),pxToEm(24));
        display: flex;
        justify-content: flex-end;
        li{
            padding:30px;
            position: relative;  
            @include mediaquery(null,$sm){
                padding:20px;
            }
            @include mediaquery($sm,null){
                padding:30px;
            }
        }
        svg{
            width: 60px;
            height: 60px;
            stroke: black;
            stroke-width: 2;
            fill:none;
            stroke-dasharray:200 201;
            stroke-dashoffset:200;
            @include position(absolute,$top:50%,$left:50%);
            @include mediaquery(null,$sm){
                transform: translate(-50%,-50%) rotate(-90deg) scale(0.6);
            }
            @include mediaquery($sm,null){
                transform: translate(-50%,-50%) rotate(-90deg);
            }
        }
        li.slick-active svg{            
            animation:yoOVDSqT_draw 5s linear forwards;
        }
    }
    @include mediaquery(null,$sm){
        .slider-banner{
            width: 84%;
        }
        .txt{
            width: 8%;
        }
    }
    @include mediaquery($sm,null){
        .slider-banner{
            width: 88%;
        }
        .txt{
            width: 7%;
        }
    }
}
.indx .prod-cat{    
    a{
        display: block;
    }
    .imgbx{
        @include mediaquery(null,$lg){
            img.show-pc{
                display: none;
            }
        }
        @include mediaquery($lg,null){
            img.show-mo{
                display: none;
            }
        }
    }
    .col-3{
        @include mediaquery(null,$sm){
            > div{
                margin-bottom: 20px;
            }
        }
        @include mediaquery($sm,$lg){
            max-width: 480px;
            width:90%;
            margin: 0 auto;
            div{
                margin-bottom: 24px;
            }
        }
        @include mediaquery($lg,null){ 
            display: flex;           
            div{
                flex: 0 0 calc((100% - 24px) / 3);
            }
            div:nth-child(3n+2){
                margin-right: 12px;
                margin-left: 12px;
            }
        }
        @include mediaquery($md,null){
            > div{
                transform: scale(1);
                transition: transform 0.4s cubic-bezier(0.260, 0.005, 0.000, 1.035);
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
    .bg1{
        @include position(absolute,$left:0);
        @include mediaquery(null,$sm){
            height: 84%;
            bottom:-13%;
        }
        @include mediaquery($sm,$lg){
            height: 84%;
            bottom:-6vw;
        }
        @include mediaquery($lg,null){
            height: 20vw;
            min-height: 280px;
            bottom:-6vw;
        }
    }
    .bg2{
        @include position(absolute,$right:0);
        @include mediaquery($sm,null){
            height: 276px;
            top:8vw;
        }
    }
    .bxname{
        @include mediaquery(null,$sm){            
            @include position(absolute,$left:52%,$top:-30px);
        }
        @include mediaquery($sm,null){
            @include position(absolute,$left:35.5vw,$top:calc(8vw - 0.85em));
        }
    }     
    @include mediaquery(null,$sm){        
        margin-top: 130px;
        margin-bottom: 150px;
    }
    @include mediaquery($sm,$lg){  
        margin-top: 18vw;
        margin-bottom: 19vw;
    }
    @include mediaquery($lg,null){   
        margin-top: 14vw;
        margin-bottom: 15vw;
    }
}
.indx .news{
    .bg1{
        z-index: -1;
        @include mediaquery(null,$sm){
            // width: 100vw;
            height: 50%;
            @include position(absolute,$top:40px,$left:0);
        }
        @include mediaquery($sm,$md){
            // width: 100vw;
            height: 80%;
            @include position(absolute,$top:67px,$left:50%);
            transform: translateX(-50%);
        }
        @include mediaquery($md,$lg){
            // width: calc(100% + 48px);
            height: 80%;
            @include position(absolute,$top:80px,$left:-24px);
        }
        @include mediaquery($lg,null){
            // width: 120%;
            height: 450px;
            @include position(absolute,$top:80px,$left:-5%);
        }
    }
    .bg3{        
        .mimg{
            @include mediaquery(null,$sm){
                width: 60%;
                @include position(absolute,$left:62%,$bottom:46px);
            }
            @include mediaquery($sm,$lg){
                width: 50%;
            }
            @include mediaquery($sm,null){
                @include position(absolute,$left:70%,$bottom:120px);
            }
        }
        // width: 100%;
        height: 80%;
        z-index: -1;
        @include position(absolute,$left:0,$bottom:0);
    }
    .bxname{
        z-index: -1;
        @include mediaquery(null,$sm){            
            @include position(absolute,$left:52%,$top:-30px);
        }
        @include mediaquery($sm,null){            
            @include position(absolute,$top:calc(80px - 0.85em),$right:0);
        }
    }
    .tagbtn a{
        color:white;
    }
    .tagbtn a:after{
        background-color: white;
    }       
    @include mediaquery(null,$sm){
        padding-bottom: 70px;
        margin-bottom: 120px;
        .tagbtn{
            margin-top: 0px;
        }
    }
    @include mediaquery($sm,$lg){
        margin-bottom: 25vw;
        padding-bottom: 13vw;
    }
    @include mediaquery($lg,null){        
        margin-bottom: 18vw;
        padding-bottom: 4vw;
        .tagbtn{
            margin-bottom: 30px;
            margin-top: 60px;
        }
    }
    @include mediaquery($sm,$md){        
        .tagbtn{
            max-width: 100vw;
            margin-left: calc(-50vw + 260px);
        }
    }
    @include mediaquery($md,$lg){ 
        .tagbtn{
            max-width: 80vw;
            margin-left: calc(-40vw + 360px);
        }
        .archive{
            display: block;
            .items{
                width: 400px;
                margin:0 auto 60px !important;
            }
        }
    }
}
.indx-brand{
    position: relative;
    .bg2{
        // width: 64.5%;
        @include position(absolute,$right:0);
        @include mediaquery(null,$sm){
            height: calc(100% + 20px);
            top:-20px;
        }
        @include mediaquery($sm,null){
            height: calc(100% + 35px);
            top:-35px;
        }
    }
    .bxname{
        @include mediaquery(null,$sm){
            @include position(absolute,$left:52%,$top:calc(-20px - 0.8em));
        }
        @include mediaquery($sm,null){
            @include position(absolute,$left:51vw,$top:calc(-35px - 0.8em));
        }
    }
    .slick-slide > div > div{
        height: 100%;
    }
    .slick-btn{
        .btn-slick-next,
        .btn-slick-prev{
            background-color: white;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
            @include position(absolute,$top:50%);
            transform: translateY(-50%);
            cursor: pointer;
            @include border();
            @include mediaquery(null,$sm){
                width:50px;
                height: 50px;
            }
            @include mediaquery($sm,null){
                width:70px;
                height: 70px;
            }
        }
        span{
            width: 10px;
            @include position(absolute,$top:50%,$left:50%);
            transform: translate(-50%,-50%);
        }
        @include mediaquery(null,$xxl){
            .btn-slick-prev{
                left:50px;
            }
            .btn-slick-next{
                right:50px;
            }
        }
        @include mediaquery($xxl,null){
            .btn-slick-prev{
                left:calc((100vw - 1200px - 35px)/2);
            }
            .btn-slick-next{
                right:calc((100vw - 1200px - 70px)/2);
            }
        }
    }
    @include mediaquery(null,$sm){
        padding-bottom: $body-pdbtm-mo;
        .titlebx{
            margin-bottom: 1em;
        }
    }
    @include mediaquery($sm,null){
        padding-bottom: $body-pdbtm-pc;
        .titlebx{
            margin-bottom: 10px;
        }
    }
}
.indx .brands{
    .items{
        display: block;
    }
    @include mediaquery(null,$sm){
        &.archive{
            .stitle{
                font-size: 13px;
            }
            .mtitle{
                font-size: 18px;
            }
            .mtxt{
                padding:16px;
            }
        }
    }
    @include mediaquery(null,$md){            
        .slick-slide{
            padding:10px 15px;
        }
    }
    // @include mediaquery($sm,$md){
    //     .slick-list{
    //         padding-left: calc((100vw - 480px) / 2);
    //     }
    //     .items{
    //         width:480px;
    //     }
    // }
    @include mediaquery($md,$lg){  
        .slick-slide{
            padding:40px 20px;
        }
        // .items{
        //     width:calc((100vw - 40px)/2);
        // }
    }
    @include mediaquery($lg,null){ 
        .slick-list{
            padding-left: calc((100vw - 1200px) / 2);
        }         
        .slick-slide{
            padding:40px 20px;
        }
        .items{
            width:calc((1168px - 80px) / 3);
            display: block;
        }
    }
}
.circle{
    @include position(absolute,$right:0,$bottom:0);
    // fill:none;
    // stroke: white;
    // stroke-width: 2;
    width: 60px;
}
@keyframes yoOVDSqT_draw{
    100%{stroke-dashoffset:0;}
}