@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500&family=Lora:wght@400;500&family=Roboto:wght@100;300;400;700&display=swap');

$mfont: 'Roboto','Noto Sans TC', sans-serif;
$fen:'Lora', serif;
// $ftitle:'EBGaramond-ExtraBold', serif;
// $ftxt:'EBGaramond-Regular', serif;
// $f-Regular:'EBGaramond-Regular', serif;
// $f-bold:'EBGaramond-Bold', serif;
//-------------------------文字顏色-------------------------//
// $pink:#f8756b;
// $brwon:#b58a4c;
// $blue:#12305a;
// $orange:#ff9c00;
// $yel:#ffc423;
// $green:#00583d;
// $fontcolor:#413523;
// $menu:#19306a;

// $acolor:#e60012;
$color1:#009fe3;
$color2:#e7f7ff;
// $red:#e60012;
// $gray:#eeeeee;
// $lgray:#dcdddd;

$maxConentW:1200px;
$pdr:8px;
$pdr2:16px;
$pdr-m:20px;
$pdr-m2:40px;
// $pdr-body:40px;

$headH-desk: 100px; 
$headH-medium: 70px;
$headH-medium2:140px;
$headH-mobile:50px;

//body padding
$body-pdbtm-pc:100px;
$body-pdbtm-mo:60px;