.news .tagbtn{
    a{
        @include font-color($color1,400,1.38);
        @include font-size(pxToEm(13),pxToEm(22));
        display: inline-block;
        position: relative;
        text-align: center;
        opacity: 0.7;        
        @extend .line_hover;
        @include mediaquery(null,$sm){ 
            padding:1em 0.2em;
            &.lh2{
                padding: 0.2em 0.2em;
            }
            i{
                display: block;
            }
        }
        @include mediaquery($sm,$lg){
            padding:1.5em 0.8em;
            font-size: pxToEm(18);
            margin-right: 1em; 
            &.lh2{
                padding: 0.8em;
            }
            i{
                display: block;
            }
        }
        @include mediaquery($lg,null){   
            padding:.8em;
            margin-right: 0.5em; 
        }
    }
    a:after{
        bottom:0;
        @include mediaquery(null,$sm){ 
            height: 2px;
        }
        @include mediaquery($sm,null){   
           height: 5px;
        }
    }
    a.active{
        opacity: 1;
        &:after{                    
            width: 100%;
        }
    }
    margin-bottom: 40px;   
    @include mediaquery($md,null){     
        a:hover{
            opacity: 1;
            &:after{                    
                width: 100%;
            }
        }
    }
    @include mediaquery(null,$sm){
        margin-top: -30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    @include mediaquery($sm,$lg){   
        display: flex;     
        align-items: center;
        padding:0;  
        max-width:calc(100% - 48px);    
    }
}
.news .detail,.news .archive{
    .date-tag{
        @include font-color(#9e9e9e,400,1.6);
        @include font-size(pxToEm(12),pxToEm(16));
        display: flex;
        align-items: center;
    }
    .mtag{
        border:1px solid #9e9e9e;
        margin-left: 1em;
        padding:0px 10px 0;
        @include mediaquery(null,$sm){
            padding-top: 3px;
        }
        @include mediaquery($sm,null){
            padding-top: 2px;
        }
    }
    .mtitle{
        @include font-color($color1,400,1.6);
        @include font-size(pxToEm(15),pxToEm(22));
        margin:0.5em 0;
    }
    .page{
        @include mediaquery($sm,null){
            margin-top: 90px;
        }
    }
}
.news .archive{
    .bgcover{
        padding-bottom: 62.5%;
        background-image: url(../images/news_img.jpg);
    }
    .mimg{
        background-color: #e7f7ff;
    }
    .mtxt{
        position: relative;
        width: 100%;
        @include mediaquery(null,$sm){
            padding:16px 50px 24px 20px;
        }
        @include mediaquery($sm,$lg){
            padding:30px 0px 24px 30px;
            .mtitle,.stext{
                padding-right: 80px;
            }
        }
        @include mediaquery($lg,null){
            padding:30px 150px 24px 32px;
        }
    }    
    .stext{
        @include font-color(#101010,300,1.75);
        @include font-size(pxToEm(14),pxToEm(16));
    }
    .rtbn{
        @include position(absolute,$top:50%);
        transform: translateY(-50%);
        .st0{
            fill:#101010;
        }
        .st1{
            stroke: #101010;
        }
        .up{
            display: none;
        }
        @include mediaquery(null,$sm){
            width: 20px;
            right: 20px;
        }
        @include mediaquery($sm,null){
            width: 40px;
        }
        @include mediaquery($sm,$lg){
            right: 30px;
        }
        @include mediaquery($lg,null){
            right:42px;
        }
    }    
    .items{
        background-color: white;
        margin-bottom: 16px; 
        box-shadow: 0px 10px 30px 0 rgba(160, 160, 160, 0.15);
    }
    @include mediaquery($md,null){
        .mdate,.stext,.mtag{
            transition: all 0.6s;
        }
        a:hover{
            .mdate,.stext{
                color:$color1;
            }
            .mtag{
                color:white;
                background-color: $color1;
                border-color: $color1;
            }
            .rtbn{
                .up{
                    display: block;
                    @include position(absolute,$top:0,$left:0px); 
                }
                .st0{
                    fill:$color1;
                }
                .st1{
                    animation:fJOOgbfF_draw linear 0.6s forwards;
                    stroke: $color1;
                    stroke-dasharray:483 485;
                    stroke-dashoffset:485;
                }
            }
        }
    }
    @include mediaquery($md,$lg){
        display: flex;
        padding:0;  
        max-width:calc(100% - 48px); 
        .items{
            flex: 0 0 calc((100% - 40px) / 2);
            &:nth-child(odd){
                margin-right: 20px;
            }
            &:nth-child(even){
                margin-left: 20px;
            }
        }
        .rtbn{
            margin-top: 30px;
        }
    }
    @include mediaquery($lg,null){
        a{
            display: flex;
        }
        .mimg{            
            width: 320px;
            flex:0 0 320px;
        }
        .mtitle{
            @include clamp(1.6,1);
        }
        .stext{
            @include clamp(1.75,2);
        }
    }
}
.news .detail{    
    .editor_inner{
        @include mediaquery(null,$md){
            padding:40px 0 32px;
            border-top: 1px solid $color1;
        }
        @include mediaquery($md,null){
            padding:32px 0 64px;
            border-top: 2px solid $color1;
        }
    }
}