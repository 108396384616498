.brands .archive,.brands.archive{
    .bgcover{
        padding-bottom: 71.11%;
    }
    .mimg{
        background-color: white;
    }
    .mtxt{
        width: 100%;
        @include mediaquery(null,$sm){
            padding:30px 20px;
        }
        @include mediaquery($sm,null){
            padding:30px 20px 24px;
        }
    }
    .stitle{
        @include font-color(#9a9a9a,400,1.5);
        @include font-size(pxToEm(14),pxToEm(16));
    }
    .mtitle{
        @include font-color($color1,400,1.45);
        @include font-size(pxToEm(19),pxToEm(22));
        margin:.2em 0 0.8em;
        // @include mediaquery(null,$sm){
        //     margin-top: 0.2em;
        // }
    }
    .stext{
        @include font-color(#2e2e2e,300,1.8);
        @include font-size(pxToEm(14),pxToEm(16));
        // min-height: 3.6em;
        // @include clamp(1.8,2);
    }
    .items{
        background-color: #f5f4f4;
        @include mediaquery(null,$md){            
            box-shadow: 0 5px 10px 0 hsla(0,0%,62.7%,.3);
        }
        @include mediaquery($md,null){
            box-shadow: 0 5px 10px 0 hsla(0,0%,62.7%,.3);
        }
        .bgcover{
            transform: scale(1);
            transition: transform 0.6s cubic-bezier(0.260, 0.005, 0.000, 1.035);
        }
        // transform: scale(1);
        @include mediaquery($md,null){
            &:hover{
                transform: scale(1.1);
                .bgcover{
                    transform: scale(0.92);
                }
            }
        }
    }
}
.brands .archive .items{
    @include mediaquery(null,$md){
        margin-bottom: 30px;
    }
    @include mediaquery($md,$lg){
        flex: 0 0 calc((100% - 40px) / 2);
        margin-bottom: 45px; 
        &:nth-child(odd){
            margin-right: 20px;
        }
        &:nth-child(even){
            margin-left: 20px;
        }
    }
    @include mediaquery($lg,null){
        flex: 0 0 calc((100% - 84px) / 3);
        margin-bottom: 60px; 
        &:nth-child(3n+2){
            margin-right: 42px;
            margin-left: 42px;
        }
    }
}
.brands .page{
    @include mediaquery($sm,null){
        margin-top: 35px;
    }
}