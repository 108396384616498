/*----------------------theme------------------------*/
body{    
    font-family: $mfont;    
    overflow-x: hidden;
    opacity: 0;
    scroll-behavior: smooth;
    // visibility: hidden;
    &.fade-in{
        opacity: 1;
        transition:opacity  2s ease ;
        // visibility: visible;
    } 
    // @include mediaquery(null,$lg){
    //     padding-top: $headH-mobile;
    // }
    // @include mediaquery($lg,null){
    //     padding-top: $headH-desk;
    // }
}
#Wapper{
    position: relative;
}
main{
    // overflow-x: hidden;
    @include mediaquery(null,$sm){
        padding-bottom: $body-pdbtm-mo;
    }
    @include mediaquery($sm,null){
        padding-bottom: $body-pdbtm-pc;
    }
    @include mediaquery(null,$md){
        padding-top: $headH-mobile;
    }
    @include mediaquery($md,$lg){
        padding-top: $headH-medium2;
    }
    @include mediaquery($lg,null){
        padding-top: $headH-desk;
    }
}
.row{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @include mediaquery(null,$md){
        max-width:520px;
        padding:0 $pdr-m;
    }
    @include mediaquery($md,$lg){ 
        max-width:720px;
        padding:0 $pdr-m;
        &:not(.col){
            display: flex;
            flex-wrap: wrap;
        } 
    }
    @include mediaquery($lg,null){
        max-width: $maxConentW;  
        padding:0 $pdr2;  
        width:calc(100% - 48px);  
        &:not(.col){
            display: flex;
            flex-wrap: wrap;
        } 
        &.pd60{
            padding:0 60px;
        }
    }
}
.pgkv{
    position: relative;
    .row{
        @include mediaquery(null,$lg){
            @include position(absolute,$bottom:-30px,$left:0%);
            background-color: $color1;
        }
        @include mediaquery($lg,null){
            @include position(absolute,$bottom:-3vw,$left:50%);
            transform: translateX(-50%);
        }
    }
    .bgcolor{
        display: flex;
        align-items: center;
        background-color: $color1;
        @include mediaquery(null,$lg){
            height: 100px;
            min-width: 225px;
        }
        @include mediaquery($lg,null){
            height: 10.5vw;
            width: 25vw;
            min-width: 400px;
        }
    }
    .titlebx{        
        @include font-color(white,500,1);
        @include mediaquery(null,$lg){
            // padding:4.5em 7.5em 4.5em 4em;
            padding:2em $pdr-m;
        }
        @include mediaquery($lg,null){
            padding-left: 70px;
            &:before{
                content:"";
                @include position(absolute,$top:0,$left:-400px);
                z-index: -1;
                width:432px;
                height: 100%;
                background-color: $color1;
            }
        }
        @media all and (min-width: 1600px) {
            &:before{
                width:calc((100vw - 1168px)/2);
                left:calc(-50vw + 600px);
            }
        }
    }
    .mtitle{
        @include font-size(pxToEm(22),pxToEm(36));
        display: inline-block;
    }
    .mtitle + span{
        font-family: $fen;
        @include font-color(white,500,1);
        @include font-size(pxToEm(14),pxToEm(18));
        margin-left: 1em;
    }
    .pgname{
        font-size: 10vw;
        @include font-color(white,100,1);
        letter-spacing: 0;
        opacity: 0.5;
        @include position(absolute,$right:0,$bottom:-0.15em);
        @include mediaquery(null,$md){
            display: none;
        }
    }
    .bgimg{
        overflow: hidden;
    }
    .bgcover{
        @include mediaquery(null,$sm){
            padding-bottom: 45.57%;
        }
        @include mediaquery($sm,$xl){
            // padding-bottom: 18.23%;
            height: 280px;
        }
        @include mediaquery($xl,null){
            height: 350px;
        }
    }
    @include mediaquery(null,$sm){
        margin-bottom: 80px;
    }
    @include mediaquery($sm,$lg){
        margin-bottom: 100px;
    }
    @include mediaquery($lg,null){
        margin-bottom: calc(5vw + 60px);
    }
}
//overwrite pgkv
.contact,.product{
    .pgkv{
        margin-bottom: 0;
        .row{
            bottom:0px;
        }
        @include mediaquery(null,$sm){
            .bgcover{
                padding-bottom: 60%;
            }         
            .bgcolor{
                height: 120px;
            }
            .titlebx{
                margin-top: -28px;
            }
            + .overlaybx{
                margin-top: -40px;
            }
        }
        @include mediaquery($sm,$xl){  
            .bgcover{
                height: 368px;
            }         
            .bgcolor{
                height: 15vw;
            }
            .titlebx{
                margin-top: -3vw;
            }
            + .overlaybx{
                margin-top: -4vw;
            }
        }
        @include mediaquery($xl,null){    
            .bgcover{
                height: 460px;
            }         
            .bgcolor{
                height: 13vw;
            }
            .titlebx{
                margin-top: -3vw;
            }
            + .overlaybx{
                margin-top: -4vw;
            }
        }
        .pgname{
            bottom:calc(3vw - 0.1em);
        }
    }
}
.btn{    
    display: inline-block;
    @include font-color(white,400,1.6);
    @include font-size(pxToEm(15),pxToEm(18));
    @extend .bg-grad;   
    
    @include mediaquery(null,$sm){
        width:148px;
        padding:0.8em 0em;
    }
    @include mediaquery($sm,null){
        width:240px;
        padding:1.45em 2em;
    }
}
.bg-grad{
    position: relative;
    background: rgb(0,147,221);
    background: -moz-linear-gradient(0deg, rgba(0,147,221,1) 0%, rgba(33,200,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,147,221,1) 0%, rgba(33,200,255,1) 100%);
    background: linear-gradient(0deg, rgba(0,147,221,1) 0%, rgba(33,200,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0093dd",endColorstr="#21c8ff",GradientType=1);
    span{
        position: relative;
        z-index: 10;
        display: block;
    }
    &:before{
        content:"";
        display: block;
        width: 100%;
        height: 100%;
        @include position(absolute,$top:0,$left:0);
        z-index: 8;
        background-color: $color1;
        opacity: 0;
        transition: opacity 0.6s;
    }
    @include mediaquery($md,null){
        &:hover{
            color: white;
            &:before{
                opacity: 1;
            }
        }
    }
}

.line_hover{
    &:after{
        content:"";
        display: block;
        width: 0;
        background-color: $color1;
        transition: 0.4s cubic-bezier(0.260, 0.005, 0.000, 1.035);
        @include position(absolute,$bottom:-10px,$left:auto,$right:0);
    }
    @include mediaquery($md,null){
        &:hover:after{                 
            width: 100%;
            right: auto;
            left:0;
        }
    }
}


#hdtxt{
    > div:not(:first-child){
        display: none;
    }
}
#scrtop{
    width: 100%;
    height: 1px;
    @include mediaquery(null,$md){
        transform: translateY(-60px);
    }
    @include mediaquery($md,$lg){
        transform: translateY(-90px);
    }
    @include mediaquery($lg,null){
        transform: translateY(-120px);
    }
}