.asj.fade{
    opacity: 0;
    transition: opacity 1.5s cubic-bezier(0.25, 1, 0.5, 1);
    &.animated{
        opacity: 1;
    }
}
.asj.fade-up{
    opacity: 0;
    transform: translateY(80px);
    transition: opacity 1s,transform 0.8s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    &.animated{
        opacity: 1;
        transform: translateY(0px);
    }
}
.asj[data-delay="100"]{
    transition-delay: 0.1s;
}
.asj[data-delay="200"]{
    transition-delay: 0.2s;
}
.asj[data-delay="300"]{
    transition-delay: 0.3s;
}
.asj[data-delay="500"]{
    transition-delay: 0.5s;
}
//kv
.pgkv{
    .row{
        opacity: 0;
        margin-left: -40px;
        transition: opacity 1.5s,margin-left 0.8s;
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-delay: 0.8s;
    }
    .pgname{
        opacity: 0;
        transform: translateX(5%);
        transition:opacity 1.5s,transform 1s;
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-delay: 0.8s;
    }
    .bgcover{
        opacity: 0;
        transform: scale(1.1);
        filter: blur(50px);
        transition: opacity 1.5s  1s,transform 0.8s 1s,filter 0.8s 1s;
        transition-timing-function:cubic-bezier(0.25, 1, 0.5, 1) ;
        transition-delay: 0.6s;
    }
}
body.fade-in .pgkv{    
    .row{
        opacity: 1;
        margin-left: 0px;
    }
    .pgname{
        opacity: 0.5;
        transform: translateX(0);
    }
    .bgcover{
        opacity: 1;
        transform: scale(1);
        filter: blur(0px);
    }
}
//index
body.fade-in .indx .indx-banner{   
    .slider-banner { 
        opacity: 1;
        filter: blur(0px);
    }
    .bg1{
        opacity: 1;
        right: 0%;
    }
}
.indx{
    .bxname{
        opacity: 0;
        transform: translateX(-1%);
        transition: transform 1s 0.6s,opacity 1s 0.6s;
        transition-timing-function:cubic-bezier(0.25, 1, 0.5, 1);
    }
    .bxname.animated{
        opacity: 1;
        transform: translateX(0%);
    }
    .indx-banner{
        .slider-banner {
            opacity: 0;            
            filter: blur(50px);
            transition: opacity 1.5s,filter 1.5s;
            transition-timing-function:cubic-bezier(0.22, 1, 0.36, 1);
            transition-delay: 0.7s;
        }
        .bg1{
            opacity: 0;
            right: -50%;
            transition: right 0.6s 1.5s,opacity 0.6s 1.5s;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        }
    }
    .prod-cat{
        .bg1,.bg2{
            opacity: 0;
            width: 0;
            transition: width 0.6s,opacity 0.6s;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        }
        .bg1.animated{
            width: 70.3%;
            opacity: 1;
        }
        .bg2.animated{
            width: 35.6%;
            opacity: 1;
        }
        .zoom-in{
            overflow: hidden;
            .imgbx{                
                opacity: 0;
                transform: scale(1.2);
                filter: blur(40px);
                transition: opacity 1.5s 0.5s,transform 0.6s 0.5s,filter 1s 0.5s;
                transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
            }
            &.animated .imgbx{
                filter: blur(0px);
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    .news{
        .bg1{
            opacity: 0;
            width: 0;
            transition: width 0.6s,opacity 0.6s;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        }
        &.animated .bg1{
            opacity: 1;
            @include mediaquery(null,$md){
                width: 100vw;
            }
            @include mediaquery($md,$lg){
                width: calc(100% + 48px);
            }
            @include mediaquery($lg,null){
                width: 120%;
            }
        }
        .bg3{
            opacity: 0;
            width: 0;
            transition: width 0.4s 0.2s,opacity 0.6s 0.2s;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        }
        &.animated .bg3{
            width: 100%;
            opacity: 1;
        }
        #hdtxt{
            opacity: 0;
            transition: opacity 1.5s;
            transition-timing-function:cubic-bezier(0.25, 1, 0.5, 1);
        }
        &.animated #hdtxt{
            opacity: 1;
        }
        .archive .items{
            transition-delay: 0.4s;
        }
    }
    .indx-brand{
        .bg2{
            opacity: 0;
            width: 0;
            transition: width 0.4s,opacity 0.6s;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        }
        &.animated .bg2{
            width: 64.5%;
            opacity: 1;
        }
    }
}

//brands
.brands .items.asj.zoom-in{
    overflow: hidden;
    opacity: 0;
    transition-timing-function:cubic-bezier(0.25, 1, 0.5, 1);
    .mimg{
        opacity: 0;
        transform: scale(1.5);
        filter: blur(40px);
        transition: opacity 1.5s ,transform 0.6s,filter 0.6s;
        transition-timing-function:cubic-bezier(0.25, 1, 0.5, 1);
        transition-delay: 0.6s;
    }
    &.animated{
        opacity: 1;
        transition: opacity 1.5s 0.2s,transform  0.4s;
    }
    &.animated .mimg{
        filter: blur(0px);
        opacity: 1;
        transform: scale(1);
    }
}
//f_contact
.f-contact.asj{
    opacity: 0;
    transition: opacity 1.5s cubic-bezier(0.25, 1, 0.5, 1);
    .fade-up{
        opacity: 0;
        transform: translateY(60px);
        transition-timing-function:cubic-bezier(0.25, 1, 0.5, 1);
        transition: opacity 1s,transform 0.4s;
        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                transition-delay: ($i * 1) * 0.1s;
            }
        }
    }
    &.animated{
        opacity: 1;
        .fade-up{
            opacity: 1;
            transform: translateY(0px);
        }
    }
}