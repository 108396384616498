@charset "UTF-8";
/* Slider */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500&family=Lora:wght@400;500&family=Roboto:wght@100;300;400;700&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*----------------------通用設定------------------------*/
p::selection, h1::selection, h2::selection, h3::selection, h4::selection, b::selection, strong::selection, span::selection, li::selection, div::selection, a::selection, img::selection {
  color: #fff;
  background: #000; }

a {
  outline: none; }

a, a:hover {
  text-decoration: none;
  transition: 0.4s;
  color: initial; }

*:focus {
  outline: none; }

img {
  vertical-align: middle;
  max-width: 100%; }

/*----------------------共用樣式------------------------*/
.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.mpsrel {
  position: relative; }

.bgcover {
  width: 100%;
  height: 0;
  background: center / cover; }

@media all and (min-width: 769px) {
  .col-temp-3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -8px;
    margin-right: -8px; }
    .col-temp-3 > div {
      max-width: calc((100% - 6em) / 3);
      flex: 0 0 calc((100% - 6em) / 3);
      margin: 1em; } }

@media all and (min-width: 769px) {
  .col-temp-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -8px;
    margin-right: -8px; }
    .col-temp-2 > div {
      max-width: calc(50% - 2em);
      flex: 0 0 calc(50% - 2em);
      margin: 1em; } }

/*----------------------編輯器------------------------*/
.editor_inner {
  color: black;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .editor_inner {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .editor_inner {
      font-size: 1rem; } }
  .editor_inner ul, .editor_inner ol {
    padding-left: 10px; }
  .editor_inner ol {
    list-style: decimal; }
  .editor_inner ul {
    list-style: disc; }
  .editor_inner img {
    max-width: 100%;
    height: auto !important; }
  .editor_inner b, .editor_inner strong {
    font-weight: bold;
    font-style: inherit; }
  .editor_inner em {
    font-style: italic; }
  .editor_inner a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_inner iframe {
    max-width: 100%; }
  .editor_inner h1, .editor_inner h2, .editor_inner h3, .editor_inner h4, .editor_inner h5, .editor_inner h6 {
    margin: inherit; }
  .editor_inner blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    border-left: 5px solid #ccc;
    margin-left: 40px; }
  @media all and (max-width: 575px) {
    .editor_inner {
      line-height: 1.64; } }
  @media all and (max-width: 768px) {
    .editor_inner.show-pc {
      display: none; }
    .editor_inner + .back {
      margin-top: 50px; } }
  @media all and (min-width: 769px) {
    .editor_inner.show-mo {
      display: none; }
    .editor_inner + .back {
      margin-top: 100px; } }

/*-------------------背景圖片 桌手機分開-----------------*/
@media all and (max-width: 575px) {
  .bgcover[data-src-lg] {
    content: attr(data-src-lg, url); } }

@media all and (min-width: 576px) {
  .bgcover[data-src-sm] {
    content: attr(data-src-sm, url); } }

/*---------------------video----------------------*/
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive .embed-responsive-item {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .embed-responsive.embed-responsive-16by9:before {
    content: "";
    display: block;
    padding-top: 56.25%; }

#topBtn {
  display: block;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: 0s; }
  #topBtn .svg {
    width: 40px; }
    #topBtn .svg .st0 {
      fill: #777777;
      transition: fill 0.6s; }
    #topBtn .svg .st1 {
      fill: #FFFFFF;
      transform: translateY(0px);
      transition: transform 0.6s; }
  #topBtn.open {
    opacity: 1;
    visibility: visible;
    z-index: 99; }
  #topBtn.change {
    position: absolute; }
  @media all and (max-width: 575px) {
    #topBtn .svg {
      width: 32px; } }
  @media all and (max-width: 768px) {
    #topBtn {
      position: fixed;
      right: 20px;
      bottom: 15px; }
      #topBtn.change {
        bottom: -60px; } }
  @media all and (min-width: 769px) {
    #topBtn {
      position: fixed;
      right: 20px;
      bottom: 20px; }
      #topBtn.change {
        bottom: -80px; }
      #topBtn:hover .svg circle.st0 {
        fill: #009fe3; }
      #topBtn:hover .svg path.st0 {
        fill: black; }
      #topBtn:hover .svg .st1 {
        transform: translateY(-12px); } }
  @media all and (min-width: 1440px) {
    #topBtn {
      right: 100px;
      bottom: 60px; }
      #topBtn.change {
        bottom: -125px; } }

.ltbn, .rtbn {
  position: relative; }
  .ltbn .st0, .rtbn .st0 {
    transition: fill 0.6s; }
  .ltbn .st1, .rtbn .st1 {
    fill: none;
    stroke-width: 12;
    stroke-linecap: round;
    stroke-linejoin: round; }

.page {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media all and (max-width: 575px) {
    .page {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .page {
      font-size: 1.125rem; } }
  .page a {
    color: #666;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.05em;
    display: block;
    padding: 0 8px; }
  .page .active a {
    color: #009fe3; }
  .page .ltbn a, .page .rtbn a {
    position: relative; }
  .page .st0 {
    fill: #666; }
  .page .st1 {
    stroke: #666; }
  .page .nopage .svg,
  .page .nopage a:hover .svg {
    opacity: 0.2;
    cursor: default; }
  @media all and (max-width: 575px) {
    .page {
      margin-top: 60px; }
      .page dt, .page dd {
        margin: 0 0.5em; }
      .page .svg {
        width: 30px; }
      .page .up {
        display: none; } }
  @media all and (min-width: 576px) {
    .page {
      margin-top: 60px; }
      .page a:hover {
        color: #009fe3; }
      .page dt {
        margin: 0 18px; }
      .page dd {
        margin: 0 10px; }
      .page .svg {
        width: 30px; }
      .page .up {
        position: absolute;
        top: 0;
        left: 8px; }
      .page .ltbn:not(.nopage) a:hover .down .st0, .page .rtbn:not(.nopage) a:hover .down .st0 {
        fill: #009fe3; }
      .page .ltbn:not(.nopage) a:hover .st1, .page .rtbn:not(.nopage) a:hover .st1 {
        animation: fJOOgbfF_draw cubic-bezier(0.26, 0.005, 0, 1.035) 0.4s forwards;
        stroke: #009fe3;
        stroke-dasharray: 483 485;
        stroke-dashoffset: 485; } }

@keyframes fJOOgbfF_draw {
  0% {
    stroke-dashoffset: 484; }
  100% {
    stroke-dashoffset: 0; } }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  background-color: white; }
  @media all and (max-width: 768px) {
    header {
      height: 50px; }
      header .title-bar {
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 50px; }
      header .navBox {
        position: fixed;
        top: 0;
        right: 0px;
        width: 100%;
        height: 100%;
        background-color: white;
        display: none; } }
  @media all and (min-width: 769px) and (max-width: 990px) {
    header .title-bar {
      width: 100%;
      height: 70px;
      position: relative; } }
  header .menuicon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10; }
    @media all and (max-width: 768px) {
      header .menuicon {
        right: 20px; } }
    @media all and (min-width: 769px) {
      header .menuicon {
        display: none; } }
    header .menuicon .mpsrel {
      width: 40px;
      height: 40px; }
    header .menuicon .bar, header .menuicon .bar:after, header .menuicon .bar:before {
      transition: all .25s;
      content: "";
      position: absolute;
      top: 0;
      right: 0%;
      height: 1.5px;
      width: 24px;
      background: #b5b5b5; }
    header .menuicon .bar {
      margin-top: 19px; }
    header .menuicon .bar:before {
      top: -8px; }
    header .menuicon .bar:after {
      top: 8px; }
  header.menu-expanded .menuicon .bar {
    background: rgba(0, 0, 0, 0); }
    header.menu-expanded .menuicon .bar:before {
      top: 0px;
      transform: rotate(45deg);
      width: 28px; }
    header.menu-expanded .menuicon .bar:after {
      top: 0px;
      transform: rotate(-45deg);
      width: 28px; }
  header .logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    transition: none; }
    @media all and (max-width: 768px) {
      header .logo {
        left: 20px;
        width: 170px; } }
    @media all and (min-width: 769px) {
      header .logo {
        width: 330px; } }
    @media all and (min-width: 769px) and (max-width: 990px) {
      header .logo {
        left: 50%;
        transform: translate(-50%, -30%); } }
    @media all and (min-width: 991px) and (max-width: 1439px) {
      header .logo {
        left: 20px; } }
    @media all and (min-width: 1440px) {
      header .logo {
        left: 80px; } }
  header ul.mmenu {
    /*位置上下左右對齊*/ }
    @media all and (max-width: 768px) {
      header ul.mmenu {
        width: 76%;
        margin: 108px auto 108px;
        padding-left: 24px; } }
    @media all and (min-width: 769px) and (max-width: 990px) {
      header ul.mmenu {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px; } }
    @media all and (min-width: 991px) {
      header ul.mmenu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100px;
        padding: 0 20px; } }
    @media all and (min-width: 1440px) {
      header ul.mmenu {
        padding: 0 80px; } }
    header ul.mmenu > li a {
      display: block;
      position: relative;
      color: #2e2e2e;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 0.05em;
      transition: color 0.6s; }
      @media all and (max-width: 575px) {
        header ul.mmenu > li a {
          font-size: 1.125rem; } }
      @media all and (min-width: 576px) {
        header ul.mmenu > li a {
          font-size: 1rem; } }
    header ul.mmenu > li a i {
      font-family: "Lora", serif;
      font-size: 0.8125rem;
      color: black;
      margin-left: 0.5em; }
    header ul.mmenu > li.active a:after {
      width: 100%; }
    @media all and (max-width: 768px) {
      header ul.mmenu > li {
        display: block;
        flex: 0 0 100%;
        margin-bottom: 40px; }
        header ul.mmenu > li a {
          border-left: 4px solid black;
          padding: 4px 24px; } }
    @media all and (min-width: 769px) {
      header ul.mmenu > li {
        margin-left: 24px; }
        header ul.mmenu > li i {
          display: none; }
        header ul.mmenu > li a {
          position: relative;
          padding: 0 8px; }
        header ul.mmenu > li a:hover {
          color: #009fe3; }
        header ul.mmenu > li a:after {
          height: 2px; } }
    @media all and (min-width: 991px) {
      header ul.mmenu > li.pos-pc {
        position: relative; } }
  header div.srhbtn {
    width: 60px;
    height: 40px;
    cursor: pointer;
    background: no-repeat url(../svg/search.svg) 31px 50%/27px 27px; }
    @media all and (max-width: 768px) {
      header div.srhbtn {
        display: none; } }
    @media all and (min-width: 769px) and (max-width: 990px) {
      header div.srhbtn {
        position: absolute;
        right: 20px;
        bottom: 16px; } }
    @media all and (min-width: 991px) {
      header div.srhbtn::before {
        content: "";
        display: inline-block;
        width: 2px;
        height: 40px;
        background-color: #999;
        margin-right: 30px;
        vertical-align: -6px; } }
  header div.closed {
    width: 40px;
    height: 40px;
    background-color: white;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s; }
    header div.closed::before, header div.closed:after {
      content: "";
      display: block;
      width: 32px;
      height: 2px;
      background-color: #707070;
      position: absolute;
      top: 20px;
      left: 5px; }
    header div.closed::before {
      transform: rotate(45deg); }
    header div.closed:after {
      transform: rotate(-45deg); }
    header div.closed.is-open {
      opacity: 1; }
    @media all and (max-width: 768px) {
      header div.closed {
        display: none; } }
    @media all and (min-width: 769px) {
      header div.closed {
        position: absolute;
        top: 0px;
        right: -2px; } }
  header nav .formtable {
    background-color: #e7f7ff;
    transition: top 0.4s cubic-bezier(0.26, 0.005, 0, 1.035); }
    header nav .formtable input {
      border-bottom: 1px solid #ccc;
      padding: 0.7em 0; }
    header nav .formtable .searchbx {
      position: relative; }
    @media all and (max-width: 768px) {
      header nav .formtable {
        position: fixed;
        top: 430px;
        left: 0;
        padding: 36px 60px;
        width: 100%; } }
    @media all and (min-width: 769px) {
      header nav .formtable {
        display: none;
        width: 480px;
        height: 100px;
        padding: 24px 60px; } }
    @media all and (min-width: 769px) and (max-width: 990px) {
      header nav .formtable {
        position: fixed;
        top: 140px;
        right: 0; } }
    @media all and (min-width: 991px) {
      header nav .formtable {
        position: fixed;
        top: 100px;
        right: 0; } }

footer {
  background-color: white;
  position: relative; }
  footer .f-info {
    position: relative; }
    footer .f-info .logo {
      position: absolute;
      top: 0;
      left: 0;
      display: block; }
    footer .f-info .mtitle {
      color: black;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.05em;
      width: 100%;
      margin-bottom: 12px; }
      @media all and (max-width: 575px) {
        footer .f-info .mtitle {
          font-size: 0.8125rem; } }
      @media all and (min-width: 576px) {
        footer .f-info .mtitle {
          font-size: 1.125rem; } }
    @media all and (max-width: 575px) {
      footer .f-info .bx {
        font-size: 0.75rem; } }
    @media all and (min-width: 576px) {
      footer .f-info .bx {
        font-size: 1rem; } }
    footer .f-info .bx i {
      color: #009fe3;
      font-weight: 300;
      line-height: 1.67;
      letter-spacing: 0.05em;
      margin-right: 0.8em; }
    footer .f-info .bx a {
      color: #666;
      font-weight: 300;
      line-height: 1.67;
      letter-spacing: 0.05em; }
    @media all and (max-width: 768px) {
      footer .f-info {
        padding-left: 100px;
        padding-top: 0.5em; } }
    @media all and (max-width: 575px) {
      footer .f-info {
        padding-left: 50px; }
        footer .f-info .logo {
          width: 30px; } }
    @media all and (min-width: 769px) {
      footer .f-info {
        display: flex;
        flex-wrap: wrap;
        padding-left: 100px;
        width: 660px; }
        footer .f-info .bx.mr {
          margin-right: 60px; } }
  footer .f-menu {
    display: flex; }
    footer .f-menu a {
      color: black;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 0.05em; }
      @media all and (max-width: 575px) {
        footer .f-menu a {
          font-size: 0.75rem; } }
      @media all and (min-width: 576px) {
        footer .f-menu a {
          font-size: 1rem; } }
    @media all and (max-width: 768px) {
      footer .f-menu {
        justify-content: space-between;
        margin: 28px auto;
        max-width: 500px; } }
    @media all and (min-width: 769px) {
      footer .f-menu {
        width: 660px;
        margin-top: 30px;
        padding-left: 100px; }
        footer .f-menu a:hover {
          color: #009fe3; }
        footer .f-menu a:not(:first-child) {
          margin-left: 2.2em; } }
    @media all and (min-width: 991px) {
      footer .f-menu {
        justify-content: flex-end;
        position: absolute;
        top: 70px;
        right: 20px; } }
    @media all and (min-width: 1440px) {
      footer .f-menu {
        position: absolute;
        top: 70px;
        right: 190px; } }
  footer .copyright {
    color: #9e9e9e;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.05em; }
    @media all and (max-width: 575px) {
      footer .copyright {
        font-size: 0.75rem; } }
    @media all and (min-width: 576px) {
      footer .copyright {
        font-size: 0.875rem; } }
    footer .copyright a, footer .copyright a:hover {
      color: #9e9e9e; }
    @media all and (max-width: 575px) {
      footer .copyright a {
        display: block; } }
    @media all and (max-width: 768px) {
      footer .copyright {
        max-width: 500px;
        margin: 0 auto; } }
    @media all and (min-width: 769px) {
      footer .copyright {
        width: 660px;
        padding-left: 100px;
        margin-top: 24px; } }
  @media all and (max-width: 768px) {
    footer {
      padding: 24px 20px 24px; } }
  @media all and (min-width: 769px) {
    footer {
      padding: 70px 20px 40px; } }
  @media all and (min-width: 769px) and (max-width: 990px) {
    footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  @media all and (min-width: 1440px) {
    footer {
      padding: 70px 190px 40px 75px; } }

.f-contact .mtitle {
  color: white;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .f-contact .mtitle {
      font-size: 1.375rem; } }
  @media all and (min-width: 576px) {
    .f-contact .mtitle {
      font-size: 1.875rem; } }

.f-contact .stext {
  color: white;
  font-weight: 300;
  line-height: 1.64;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .f-contact .stext {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .f-contact .stext {
      font-size: 1rem; } }
  @media all and (max-width: 768px) {
    .f-contact .stext {
      max-width: 25em;
      margin: 1.5em auto 1em; } }
  @media all and (min-width: 769px) {
    .f-contact .stext {
      margin: 1em auto 1.5em; } }

.f-contact a[href^="tel:"] {
  color: white;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .f-contact a[href^="tel:"] {
      font-size: 1.25rem; } }
  @media all and (min-width: 576px) {
    .f-contact a[href^="tel:"] {
      font-size: 1.875rem; } }
  .f-contact a[href^="tel:"] img {
    margin-right: 0.6em; }

.f-contact a.ediform {
  color: white;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.05em;
  display: inline-block;
  background-color: #40d4e6; }
  @media all and (max-width: 575px) {
    .f-contact a.ediform {
      font-size: 0.9375rem; } }
  @media all and (min-width: 576px) {
    .f-contact a.ediform {
      font-size: 1.375rem; } }
  @media all and (max-width: 575px) {
    .f-contact a.ediform {
      padding: .5em 2em 0;
      margin-top: 1.2em; }
      .f-contact a.ediform img {
        margin-right: 0.7em;
        vertical-align: -5px; } }
  @media all and (min-width: 576px) {
    .f-contact a.ediform {
      padding: 0.9em 2.5em;
      margin-top: 2em; }
      .f-contact a.ediform:hover {
        background-color: rgba(64, 212, 230, 0.7); }
      .f-contact a.ediform img {
        margin-right: 1.5em;
        vertical-align: -10px; } }

@media all and (max-width: 575px) {
  .f-contact div img {
    width: 25px; } }

@media all and (min-width: 576px) {
  .f-contact div img {
    width: 40px; } }

@media all and (max-width: 575px) {
  .f-contact {
    padding: 48px 16px; } }

@media all and (min-width: 576px) {
  .f-contact {
    padding: 100px 40px 50px; } }

@media all and (max-width: 768px) {
  .f-contact {
    background: no-repeat url(../images/f_contact_mo.jpg) center/cover; } }

@media all and (min-width: 769px) {
  .f-contact {
    background: no-repeat url(../images/f_contact_pc.jpg) center/cover; } }

/*----------------------theme------------------------*/
body {
  font-family: "Roboto", "Noto Sans TC", sans-serif;
  overflow-x: hidden;
  opacity: 0;
  scroll-behavior: smooth; }
  body.fade-in {
    opacity: 1;
    transition: opacity  2s ease; }

#Wapper {
  position: relative; }

@media all and (max-width: 575px) {
  main {
    padding-bottom: 60px; } }

@media all and (min-width: 576px) {
  main {
    padding-bottom: 100px; } }

@media all and (max-width: 768px) {
  main {
    padding-top: 50px; } }

@media all and (min-width: 769px) and (max-width: 990px) {
  main {
    padding-top: 140px; } }

@media all and (min-width: 991px) {
  main {
    padding-top: 100px; } }

.row {
  position: relative;
  margin-left: auto;
  margin-right: auto; }
  @media all and (max-width: 768px) {
    .row {
      max-width: 520px;
      padding: 0 20px; } }
  @media all and (min-width: 769px) and (max-width: 990px) {
    .row {
      max-width: 720px;
      padding: 0 20px; }
      .row:not(.col) {
        display: flex;
        flex-wrap: wrap; } }
  @media all and (min-width: 991px) {
    .row {
      max-width: 1200px;
      padding: 0 16px;
      width: calc(100% - 48px); }
      .row:not(.col) {
        display: flex;
        flex-wrap: wrap; }
      .row.pd60 {
        padding: 0 60px; } }

.pgkv {
  position: relative; }
  @media all and (max-width: 990px) {
    .pgkv .row {
      position: absolute;
      bottom: -30px;
      left: 0%;
      background-color: #009fe3; } }
  @media all and (min-width: 991px) {
    .pgkv .row {
      position: absolute;
      bottom: -3vw;
      left: 50%;
      transform: translateX(-50%); } }
  .pgkv .bgcolor {
    display: flex;
    align-items: center;
    background-color: #009fe3; }
    @media all and (max-width: 990px) {
      .pgkv .bgcolor {
        height: 100px;
        min-width: 225px; } }
    @media all and (min-width: 991px) {
      .pgkv .bgcolor {
        height: 10.5vw;
        width: 25vw;
        min-width: 400px; } }
  .pgkv .titlebx {
    color: white;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.05em; }
    @media all and (max-width: 990px) {
      .pgkv .titlebx {
        padding: 2em 20px; } }
    @media all and (min-width: 991px) {
      .pgkv .titlebx {
        padding-left: 70px; }
        .pgkv .titlebx:before {
          content: "";
          position: absolute;
          top: 0;
          left: -400px;
          z-index: -1;
          width: 432px;
          height: 100%;
          background-color: #009fe3; } }
    @media all and (min-width: 1600px) {
      .pgkv .titlebx:before {
        width: calc((100vw - 1168px)/2);
        left: calc(-50vw + 600px); } }
  .pgkv .mtitle {
    display: inline-block; }
    @media all and (max-width: 575px) {
      .pgkv .mtitle {
        font-size: 1.375rem; } }
    @media all and (min-width: 576px) {
      .pgkv .mtitle {
        font-size: 2.25rem; } }
  .pgkv .mtitle + span {
    font-family: "Lora", serif;
    color: white;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.05em;
    margin-left: 1em; }
    @media all and (max-width: 575px) {
      .pgkv .mtitle + span {
        font-size: 0.875rem; } }
    @media all and (min-width: 576px) {
      .pgkv .mtitle + span {
        font-size: 1.125rem; } }
  .pgkv .pgname {
    font-size: 10vw;
    color: white;
    font-weight: 100;
    line-height: 1;
    letter-spacing: 0.05em;
    letter-spacing: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    bottom: -0.15em; }
    @media all and (max-width: 768px) {
      .pgkv .pgname {
        display: none; } }
  .pgkv .bgimg {
    overflow: hidden; }
  @media all and (max-width: 575px) {
    .pgkv .bgcover {
      padding-bottom: 45.57%; } }
  @media all and (min-width: 576px) and (max-width: 1199px) {
    .pgkv .bgcover {
      height: 280px; } }
  @media all and (min-width: 1200px) {
    .pgkv .bgcover {
      height: 350px; } }
  @media all and (max-width: 575px) {
    .pgkv {
      margin-bottom: 80px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .pgkv {
      margin-bottom: 100px; } }
  @media all and (min-width: 991px) {
    .pgkv {
      margin-bottom: calc(5vw + 60px); } }

.contact .pgkv, .product .pgkv {
  margin-bottom: 0; }
  .contact .pgkv .row, .product .pgkv .row {
    bottom: 0px; }
  @media all and (max-width: 575px) {
    .contact .pgkv .bgcover, .product .pgkv .bgcover {
      padding-bottom: 60%; }
    .contact .pgkv .bgcolor, .product .pgkv .bgcolor {
      height: 120px; }
    .contact .pgkv .titlebx, .product .pgkv .titlebx {
      margin-top: -28px; }
    .contact .pgkv + .overlaybx, .product .pgkv + .overlaybx {
      margin-top: -40px; } }
  @media all and (min-width: 576px) and (max-width: 1199px) {
    .contact .pgkv .bgcover, .product .pgkv .bgcover {
      height: 368px; }
    .contact .pgkv .bgcolor, .product .pgkv .bgcolor {
      height: 15vw; }
    .contact .pgkv .titlebx, .product .pgkv .titlebx {
      margin-top: -3vw; }
    .contact .pgkv + .overlaybx, .product .pgkv + .overlaybx {
      margin-top: -4vw; } }
  @media all and (min-width: 1200px) {
    .contact .pgkv .bgcover, .product .pgkv .bgcover {
      height: 460px; }
    .contact .pgkv .bgcolor, .product .pgkv .bgcolor {
      height: 13vw; }
    .contact .pgkv .titlebx, .product .pgkv .titlebx {
      margin-top: -3vw; }
    .contact .pgkv + .overlaybx, .product .pgkv + .overlaybx {
      margin-top: -4vw; } }
  .contact .pgkv .pgname, .product .pgkv .pgname {
    bottom: calc(3vw - 0.1em); }

.btn {
  display: inline-block;
  color: white;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .btn {
      font-size: 0.9375rem; } }
  @media all and (min-width: 576px) {
    .btn {
      font-size: 1.125rem; } }
  @media all and (max-width: 575px) {
    .btn {
      width: 148px;
      padding: 0.8em 0em; } }
  @media all and (min-width: 576px) {
    .btn {
      width: 240px;
      padding: 1.45em 2em; } }

.bg-grad, .btn, .product .row .formtable .catbtn, .product .row .formtable .btngroup .sedbtn {
  position: relative;
  background: #0093dd;
  background: -moz-linear-gradient(0deg, #0093dd 0%, #21c8ff 100%);
  background: -webkit-linear-gradient(0deg, #0093dd 0%, #21c8ff 100%);
  background: linear-gradient(0deg, #0093dd 0%, #21c8ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0093dd",endColorstr="#21c8ff",GradientType=1); }
  .bg-grad span, .btn span, .product .row .formtable .catbtn span, .product .row .formtable .btngroup .sedbtn span {
    position: relative;
    z-index: 10;
    display: block; }
  .bg-grad:before, .btn:before, .product .row .formtable .catbtn:before, .product .row .formtable .btngroup .sedbtn:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    background-color: #009fe3;
    opacity: 0;
    transition: opacity 0.6s; }
  @media all and (min-width: 769px) {
    .bg-grad:hover, .btn:hover, .product .row .formtable .catbtn:hover, .product .row .formtable .btngroup .sedbtn:hover {
      color: white; }
      .bg-grad:hover:before, .btn:hover:before, .product .row .formtable .catbtn:hover:before, .product .row .formtable .btngroup .sedbtn:hover:before {
        opacity: 1; } }

.line_hover:after, header ul.mmenu > li a:after, .contact .btngrup a:after, .news .tagbtn a:after {
  content: "";
  display: block;
  width: 0;
  background-color: #009fe3;
  transition: 0.4s cubic-bezier(0.26, 0.005, 0, 1.035);
  position: absolute;
  right: 0;
  bottom: -10px;
  left: auto; }

@media all and (min-width: 769px) {
  .line_hover:hover:after, header ul.mmenu > li a:hover:after, .contact .btngrup a:hover:after, .news .tagbtn a:hover:after {
    width: 100%;
    right: auto;
    left: 0; } }

#hdtxt > div:not(:first-child) {
  display: none; }

#scrtop {
  width: 100%;
  height: 1px; }
  @media all and (max-width: 768px) {
    #scrtop {
      transform: translateY(-60px); } }
  @media all and (min-width: 769px) and (max-width: 990px) {
    #scrtop {
      transform: translateY(-90px); } }
  @media all and (min-width: 991px) {
    #scrtop {
      transform: translateY(-120px); } }

#lightcase-case {
  padding: 0 15px; }
  #lightcase-case iframe {
    max-width: calc(100vw - 30px) !important; }

#lightcase-overlay {
  background-color: rgba(0, 0, 0, 0.8); }

@media all and (max-width: 575px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 20px;
    height: 20px;
    background-size: 20px; } }

@media all and (min-width: 576px) {
  a[class*="lightcase-icon-"].lightcase-icon-close {
    width: 34px;
    height: 34px;
    background-size: 34px; } }

a[class*="lightcase-icon-"].lightcase-icon-close:before {
  display: none; }

.slickbox {
  position: relative; }
  .slickbox .slick-dots {
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10; }
    .slickbox .slick-dots li {
      margin: 0;
      width: auto;
      height: auto !important;
      transition: opacity 0.6s; }
      @media all and (max-width: 768px) {
        .slickbox .slick-dots li {
          padding: 10px 5px; } }
      @media all and (min-width: 769px) {
        .slickbox .slick-dots li {
          padding: 0 16px 1.2vw 16px; } }
    .slickbox .slick-dots span {
      display: inline-block;
      transition: background 0.6s;
      position: relative;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em; }
      @media all and (max-width: 768px) {
        .slickbox .slick-dots span {
          width: 12px;
          height: 12px; } }
      @media all and (min-width: 769px) {
        .slickbox .slick-dots span {
          width: 12px;
          height: 12px; } }

.asj.fade {
  opacity: 0;
  transition: opacity 1.5s cubic-bezier(0.25, 1, 0.5, 1); }
  .asj.fade.animated {
    opacity: 1; }

.asj.fade-up {
  opacity: 0;
  transform: translateY(80px);
  transition: opacity 1s,transform 0.8s;
  transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1); }
  .asj.fade-up.animated {
    opacity: 1;
    transform: translateY(0px); }

.asj[data-delay="100"] {
  transition-delay: 0.1s; }

.asj[data-delay="200"] {
  transition-delay: 0.2s; }

.asj[data-delay="300"] {
  transition-delay: 0.3s; }

.asj[data-delay="500"] {
  transition-delay: 0.5s; }

.pgkv .row {
  opacity: 0;
  margin-left: -40px;
  transition: opacity 1.5s,margin-left 0.8s;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 0.8s; }

.pgkv .pgname {
  opacity: 0;
  transform: translateX(5%);
  transition: opacity 1.5s,transform 1s;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 0.8s; }

.pgkv .bgcover {
  opacity: 0;
  transform: scale(1.1);
  filter: blur(50px);
  transition: opacity 1.5s  1s,transform 0.8s 1s,filter 0.8s 1s;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  transition-delay: 0.6s; }

body.fade-in .pgkv .row {
  opacity: 1;
  margin-left: 0px; }

body.fade-in .pgkv .pgname {
  opacity: 0.5;
  transform: translateX(0); }

body.fade-in .pgkv .bgcover {
  opacity: 1;
  transform: scale(1);
  filter: blur(0px); }

body.fade-in .indx .indx-banner .slider-banner {
  opacity: 1;
  filter: blur(0px); }

body.fade-in .indx .indx-banner .bg1 {
  opacity: 1;
  right: 0%; }

.indx .bxname {
  opacity: 0;
  transform: translateX(-1%);
  transition: transform 1s 0.6s,opacity 1s 0.6s;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1); }

.indx .bxname.animated {
  opacity: 1;
  transform: translateX(0%); }

.indx .indx-banner .slider-banner {
  opacity: 0;
  filter: blur(50px);
  transition: opacity 1.5s,filter 1.5s;
  transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 0.7s; }

.indx .indx-banner .bg1 {
  opacity: 0;
  right: -50%;
  transition: right 0.6s 1.5s,opacity 0.6s 1.5s;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1); }

.indx .prod-cat .bg1, .indx .prod-cat .bg2 {
  opacity: 0;
  width: 0;
  transition: width 0.6s,opacity 0.6s;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1); }

.indx .prod-cat .bg1.animated {
  width: 70.3%;
  opacity: 1; }

.indx .prod-cat .bg2.animated {
  width: 35.6%;
  opacity: 1; }

.indx .prod-cat .zoom-in {
  overflow: hidden; }
  .indx .prod-cat .zoom-in .imgbx {
    opacity: 0;
    transform: scale(1.2);
    filter: blur(40px);
    transition: opacity 1.5s 0.5s,transform 0.6s 0.5s,filter 1s 0.5s;
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1); }
  .indx .prod-cat .zoom-in.animated .imgbx {
    filter: blur(0px);
    opacity: 1;
    transform: scale(1); }

.indx .news .bg1 {
  opacity: 0;
  width: 0;
  transition: width 0.6s,opacity 0.6s;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1); }

.indx .news.animated .bg1 {
  opacity: 1; }
  @media all and (max-width: 768px) {
    .indx .news.animated .bg1 {
      width: 100vw; } }
  @media all and (min-width: 769px) and (max-width: 990px) {
    .indx .news.animated .bg1 {
      width: calc(100% + 48px); } }
  @media all and (min-width: 991px) {
    .indx .news.animated .bg1 {
      width: 120%; } }

.indx .news .bg3 {
  opacity: 0;
  width: 0;
  transition: width 0.4s 0.2s,opacity 0.6s 0.2s;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1); }

.indx .news.animated .bg3 {
  width: 100%;
  opacity: 1; }

.indx .news #hdtxt {
  opacity: 0;
  transition: opacity 1.5s;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1); }

.indx .news.animated #hdtxt {
  opacity: 1; }

.indx .news .archive .items {
  transition-delay: 0.4s; }

.indx .indx-brand .bg2 {
  opacity: 0;
  width: 0;
  transition: width 0.4s,opacity 0.6s;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1); }

.indx .indx-brand.animated .bg2 {
  width: 64.5%;
  opacity: 1; }

.brands .items.asj.zoom-in {
  overflow: hidden;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1); }
  .brands .items.asj.zoom-in .mimg {
    opacity: 0;
    transform: scale(1.5);
    filter: blur(40px);
    transition: opacity 1.5s ,transform 0.6s,filter 0.6s;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    transition-delay: 0.6s; }
  .brands .items.asj.zoom-in.animated {
    opacity: 1;
    transition: opacity 1.5s 0.2s,transform  0.4s; }
  .brands .items.asj.zoom-in.animated .mimg {
    filter: blur(0px);
    opacity: 1;
    transform: scale(1); }

.f-contact.asj {
  opacity: 0;
  transition: opacity 1.5s cubic-bezier(0.25, 1, 0.5, 1); }
  .f-contact.asj .fade-up {
    opacity: 0;
    transform: translateY(60px);
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    transition: opacity 1s,transform 0.4s; }
    .f-contact.asj .fade-up:nth-child(1) {
      transition-delay: 0.1s; }
    .f-contact.asj .fade-up:nth-child(2) {
      transition-delay: 0.2s; }
    .f-contact.asj .fade-up:nth-child(3) {
      transition-delay: 0.3s; }
    .f-contact.asj .fade-up:nth-child(4) {
      transition-delay: 0.4s; }
  .f-contact.asj.animated {
    opacity: 1; }
    .f-contact.asj.animated .fade-up {
      opacity: 1;
      transform: translateY(0px); }

.formtable input, .formtable select, .formtable textarea {
  background: #e7f7ff;
  outline: none;
  appearance: none;
  box-shadow: none;
  border: none;
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-family: "Roboto", "Noto Sans TC", sans-serif;
  color: black;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.05em;
  vertical-align: middle; }
  .formtable input:focus, .formtable select:focus, .formtable textarea:focus {
    outline: none; }
  @media all and (max-width: 575px) {
    .formtable input, .formtable select, .formtable textarea {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .formtable input, .formtable select, .formtable textarea {
      font-size: 1rem; } }

.formtable ::-webkit-input-placeholder {
  color: #777;
  font-weight: 300;
  font-family: "Roboto", "Noto Sans TC", sans-serif; }

.formtable :-moz-placeholder {
  color: #777;
  font-weight: 300;
  font-family: "Roboto", "Noto Sans TC", sans-serif; }

.formtable ::-moz-placeholder {
  color: #777;
  font-weight: 300;
  font-family: "Roboto", "Noto Sans TC", sans-serif; }

.formtable :-ms-input-placeholder {
  color: #777;
  font-weight: 300;
  font-family: "Roboto", "Noto Sans TC", sans-serif; }

@media all and (max-width: 768px) {
  .formtable input, .formtable select, .formtable textarea {
    padding: 0.8em 1em 0.6em; }
  .formtable label {
    padding: 0.2em 0; } }

@media all and (min-width: 769px) {
  .formtable input, .formtable select, .formtable textarea {
    padding: 0.8em 1.1em .6em; }
  .formtable label {
    padding: 0.8em 0; } }

.formtable label {
  position: relative;
  display: block;
  color: black;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .formtable label {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .formtable label {
      font-size: 1.125rem; } }

.formtable label.requ:before {
  content: "*";
  color: #009fe3;
  vertical-align: 4px; }

.formtable .with-errors {
  color: #009fe3;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  display: block;
  max-width: 100%;
  min-height: 1.5em;
  margin-top: 8px;
  margin-bottom: 1.6em; }
  @media all and (max-width: 575px) {
    .formtable .with-errors {
      font-size: 0.75rem; } }
  @media all and (min-width: 576px) {
    .formtable .with-errors {
      font-size: 0.875rem; } }

.formtable .srhbtn {
  cursor: pointer;
  background: no-repeat url(../svg/search.svg) center;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px; }
  @media all and (max-width: 575px) {
    .formtable .srhbtn {
      background-size: 22px; } }
  @media all and (min-width: 576px) {
    .formtable .srhbtn {
      background-size: 26px; } }

.formtable .check_radi {
  width: auto;
  position: relative;
  cursor: pointer;
  user-select: none; }
  .formtable .check_radi input[type="checkbox"], .formtable .check_radi input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    width: 100%;
    height: 100%;
    margin: 0; }
  .formtable .check_radi .boxmark {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20px;
    width: 20px;
    border: 1px solid #777;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    @media all and (max-width: 575px) {
      .formtable .check_radi .boxmark {
        transform: translate(-50%, -50%) scale(0.7);
        transform-origin: left; } }
    @media all and (min-width: 576px) {
      .formtable .check_radi .boxmark {
        transform: translate(-50%, -50%); } }
  .formtable .check_radi .boxmark:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 10px;
    height: 10px;
    background: #009fe3;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
  .formtable .check_radi input:checked ~ .boxmark:after {
    display: block; }
  .formtable .check_radi .checkmark {
    position: absolute;
    top: 0.3em;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e9f8ff; }
    @media all and (max-width: 575px) {
      .formtable .check_radi .checkmark {
        top: calc(0.3em + 1px); } }
  .formtable .check_radi input:checked ~ .checkmark {
    background-color: #009fe3; }

.formtable.col-2 .colum2 {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap; }
  @media all and (max-width: 990px) {
    .formtable.col-2 .colum2 .form-group {
      flex: 0 0 calc(50% - 12px);
      width: calc(50% - 12px); } }

@media all and (min-width: 991px) {
  .formtable.col-2 {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap; }
    .formtable.col-2 .form-group:not(.w100) {
      flex: 0 0 calc(50% - 25px);
      width: calc(50% - 25px); }
    .formtable.col-2 .form-group.w100, .formtable.col-2 .w100 {
      flex: 0 0 100%;
      width: 100%; } }

.contact .form-row {
  background-color: white;
  box-shadow: 0 0 30px 0 rgba(160, 160, 160, 0.3); }
  .contact .form-row .mtitle {
    color: #009fe3;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.05em;
    border-bottom: 1px solid #d2d2d2; }
    @media all and (max-width: 575px) {
      .contact .form-row .mtitle {
        font-size: 1.125rem; } }
    @media all and (min-width: 576px) {
      .contact .form-row .mtitle {
        font-size: 1.875rem; } }
    @media all and (max-width: 575px) {
      .contact .form-row .mtitle {
        padding: 20px 0 24px;
        margin-bottom: 30px; } }
    @media all and (min-width: 576px) {
      .contact .form-row .mtitle {
        padding: 30px 0 36px;
        margin-bottom: 40px; } }
    @media all and (min-width: 576px) and (max-width: 990px) {
      .contact .form-row .mtitle {
        font-size: 1.5rem; } }
    @media all and (min-width: 991px) {
      .contact .form-row .mtitle br {
        display: none; } }
  @media all and (max-width: 575px) {
    .contact .form-row .btn {
      margin: 12px auto 0; } }
  @media all and (min-width: 576px) {
    .contact .form-row .btn {
      margin: 40px auto 0; } }
  @media all and (max-width: 575px) {
    .contact .form-row {
      width: calc(100% - 40px);
      padding-bottom: 60px;
      padding-top: 40px;
      margin-bottom: 80px; }
      .contact .form-row img {
        width: 50px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .contact .form-row {
      padding: 30px 30px 45px;
      margin-bottom: 80px; } }
  @media all and (min-width: 991px) {
    .contact .form-row {
      padding: 60px 100px 85px;
      margin-bottom: 120px; }
      .contact .form-row textarea {
        height: 12.4em; } }

.contact .btngrup::before {
  content: "";
  height: 100%;
  background-color: #009fe3;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }
  @media all and (max-width: 575px) {
    .contact .btngrup::before {
      width: calc(100% - 20px);
      left: 20px; } }
  @media screen and (max-width: 360px) {
    .contact .btngrup::before {
      width: 100%;
      left: 0; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .contact .btngrup::before {
      width: 150%; } }
  @media all and (min-width: 991px) {
    .contact .btngrup::before {
      width: 110%; } }

.contact .btngrup a {
  color: white;
  font-weight: 400;
  line-height: 1.38;
  letter-spacing: 0.05em;
  display: inline-block;
  position: relative;
  z-index: 10;
  text-align: center;
  opacity: 0.7; }
  @media all and (max-width: 575px) {
    .contact .btngrup a {
      font-size: 0.9375rem; } }
  @media all and (min-width: 576px) {
    .contact .btngrup a {
      font-size: 1.375rem; } }
  @media all and (max-width: 575px) {
    .contact .btngrup a {
      padding: 0 0 0.1em;
      margin: 0 0.3em; } }
  @media all and (min-width: 576px) {
    .contact .btngrup a {
      padding: 0 .2em 0.9em;
      margin: 0 0.5em; } }

.contact .btngrup a:after {
  background-color: white; }
  @media all and (max-width: 575px) {
    .contact .btngrup a:after {
      height: 3px; } }
  @media all and (min-width: 576px) {
    .contact .btngrup a:after {
      height: 5px; } }

.contact .btngrup a.active {
  opacity: 1; }
  .contact .btngrup a.active:after {
    width: 100%; }

@media all and (max-width: 575px) {
  .contact .btngrup {
    padding: 20px 0px 30px; } }

@media all and (min-width: 576px) {
  .contact .btngrup {
    padding-top: 40px;
    padding-bottom: 100px;
    padding: 40px 0 100px 0; }
    .contact .btngrup a:hover {
      opacity: 1; }
      .contact .btngrup a:hover:after {
        width: 100%; } }

@media all and (max-width: 990px) {
  .contact .row.bggray {
    background-color: #f5f4f4; } }

.contact .infobx {
  background-color: #f5f4f4;
  position: relative; }
  .contact .infobx:before {
    content: "";
    height: 100%;
    background: no-repeat url(../images/contact_bg.jpg) #f5f4f4 left top/832px 649px; }
    @media all and (max-width: 575px) {
      .contact .infobx:before {
        display: none; } }
    @media all and (min-width: 576px) and (max-width: 990px) {
      .contact .infobx:before {
        position: absolute;
        top: 0;
        left: -70vw;
        width: 832px; } }
    @media all and (min-width: 991px) {
      .contact .infobx:before {
        position: absolute;
        top: 0;
        right: 720px;
        width: 832px; } }
  .contact .infobx .mitem {
    position: relative;
    z-index: 10; }
  .contact .infobx .aprt {
    color: #009fe3;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: 0.05em; }
    @media all and (max-width: 575px) {
      .contact .infobx .aprt {
        font-size: 1.125rem; } }
    @media all and (min-width: 576px) {
      .contact .infobx .aprt {
        font-size: 1.5rem; } }
  .contact .infobx .list span {
    color: #101010;
    font-weight: 300;
    line-height: 1.57;
    letter-spacing: 0.05em; }
    @media all and (max-width: 575px) {
      .contact .infobx .list span {
        font-size: 0.875rem; } }
    @media all and (min-width: 576px) {
      .contact .infobx .list span {
        font-size: 1rem; } }
  .contact .infobx .list a {
    background: no-repeat url(../images/icon/icon_mail.png) center left/24px 1em;
    color: #009fe3;
    font-weight: 300;
    line-height: 1.57;
    letter-spacing: 0.05em;
    padding-left: 40px; }
    @media all and (max-width: 575px) {
      .contact .infobx .list a {
        font-size: 0.875rem; } }
    @media all and (min-width: 576px) {
      .contact .infobx .list a {
        font-size: 1rem; } }
  @media all and (max-width: 575px) {
    .contact .infobx .list > div:not(:last-child) {
      margin-bottom: 16px; }
    .contact .infobx .list span {
      display: block;
      margin-bottom: 0.2em; }
    .contact .infobx .list a {
      background-size: 16px;
      padding-left: 28px; } }
  @media all and (min-width: 576px) {
    .contact .infobx .list > div {
      display: flex; }
    .contact .infobx .list > div:not(:last-child) {
      margin-bottom: 0.8em; }
    .contact .infobx .list span {
      flex: 0 0 10em; }
    .contact .infobx .list a {
      flex: 1; } }
  .contact .infobx .mitem:not(:last-child) {
    border-bottom: 1px solid #d2d2d2; }
  @media all and (max-width: 575px) {
    .contact .infobx .aprt {
      padding: 20px 16px; }
    .contact .infobx .list {
      padding: 0 16px 30px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .contact .infobx {
      margin-top: -60px; }
      .contact .infobx .mitem {
        padding: 20px 0; }
      .contact .infobx .aprt {
        padding: 12px 20px; }
      .contact .infobx .list {
        padding: 0 20px; } }
  @media all and (min-width: 991px) {
    .contact .infobx {
      padding: 40px 0;
      margin-top: -60px; }
      .contact .infobx .mitem {
        display: flex;
        align-items: center;
        margin-left: 30%;
        width: 65%;
        padding: 40px 0; }
      .contact .infobx .aprt {
        flex: 0 0 180px; }
      .contact .infobx .list {
        flex: 1; } }

.indx main {
  padding-bottom: 0;
  overflow: hidden; }

.indx .titlebx {
  display: flex;
  align-items: flex-end; }
  .indx .titlebx h4 {
    color: #009fe3;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.05em; }
    @media all and (max-width: 575px) {
      .indx .titlebx h4 {
        font-size: 1.375rem; } }
    @media all and (min-width: 576px) {
      .indx .titlebx h4 {
        font-size: 1.875rem; } }
  .indx .titlebx h4 + span {
    color: #101010;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.05em;
    font-family: "Lora", serif; }
    @media all and (max-width: 575px) {
      .indx .titlebx h4 + span {
        font-size: 0.875rem; } }
    @media all and (min-width: 576px) {
      .indx .titlebx h4 + span {
        font-size: 1.125rem; } }
  @media all and (max-width: 575px) {
    .indx .titlebx {
      padding: 0em .2em;
      border-left: 4px solid #101010;
      margin-bottom: 2em; }
      .indx .titlebx h4 {
        padding: 0 .6em 0 .5em; } }
  @media all and (min-width: 576px) {
    .indx .titlebx {
      padding: 0.35em 0.2em;
      border-left: 0.5em solid #101010;
      margin-bottom: 2.5em; }
      .indx .titlebx h4 {
        padding: 0 0.9em 0 0.7em; } }

@media all and (max-width: 575px) {
  .indx .btn {
    margin-top: 36px; } }

@media all and (min-width: 576px) {
  .indx .btn {
    margin-top: 56px; } }

.indx .bxname {
  color: #e7f7ff;
  font-weight: 100;
  line-height: 1;
  letter-spacing: 0.05em;
  letter-spacing: 0; }
  @media all and (max-width: 575px) {
    .indx .bxname {
      font-size: 100px; } }
  @media all and (min-width: 576px) {
    .indx .bxname {
      font-size: 13vw; } }

.indx .bg1 {
  background-color: #009fe3; }

.indx .bg2 {
  background-color: #e7f7ff; }

.indx .bg3 {
  background-color: #f5f4f4; }

.indx-banner .txt {
  color: #009fe3;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.05em;
  position: absolute;
  top: 0%;
  right: 0;
  writing-mode: vertical-lr;
  height: 75%;
  text-align: center; }
  @media all and (max-width: 575px) {
    .indx-banner .txt {
      font-size: 0.75rem; } }
  @media all and (min-width: 576px) {
    .indx-banner .txt {
      font-size: 1.125rem; } }

.indx-banner a {
  display: block;
  width: 100%;
  overflow: hidden; }

.indx-banner .bgcover {
  transform: scale(1.1);
  transition: transform 5s; }
  @media all and (max-width: 768px) {
    .indx-banner .bgcover {
      padding-bottom: 139%; } }
  @media all and (min-width: 769px) {
    .indx-banner .bgcover {
      padding-bottom: 41.67%; } }

.indx-banner .slickinit .slick-slide.slick-active .bgcover {
  transform: scale(1); }

.indx-banner .bg1 {
  z-index: -1; }
  @media all and (max-width: 575px) {
    .indx-banner .bg1 {
      width: 80%;
      height: 120px;
      position: absolute;
      right: 0;
      bottom: -5px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .indx-banner .bg1 {
      width: 56%;
      height: 22vw;
      position: absolute;
      right: 0;
      bottom: calc(-11vw + 60px); } }
  @media all and (min-width: 991px) {
    .indx-banner .bg1 {
      width: 56%;
      height: 13vw;
      position: absolute;
      right: 0;
      bottom: calc(-5vw + 60px); } }

.indx-banner .custom_paging {
  color: white;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: flex-end; }
  @media all and (max-width: 575px) {
    .indx-banner .custom_paging {
      font-size: 0.75rem; } }
  @media all and (min-width: 576px) {
    .indx-banner .custom_paging {
      font-size: 1.5rem; } }
  .indx-banner .custom_paging li {
    padding: 30px;
    position: relative; }
    @media all and (max-width: 575px) {
      .indx-banner .custom_paging li {
        padding: 20px; } }
    @media all and (min-width: 576px) {
      .indx-banner .custom_paging li {
        padding: 30px; } }
  .indx-banner .custom_paging svg {
    width: 60px;
    height: 60px;
    stroke: black;
    stroke-width: 2;
    fill: none;
    stroke-dasharray: 200 201;
    stroke-dashoffset: 200;
    position: absolute;
    top: 50%;
    left: 50%; }
    @media all and (max-width: 575px) {
      .indx-banner .custom_paging svg {
        transform: translate(-50%, -50%) rotate(-90deg) scale(0.6); } }
    @media all and (min-width: 576px) {
      .indx-banner .custom_paging svg {
        transform: translate(-50%, -50%) rotate(-90deg); } }
  .indx-banner .custom_paging li.slick-active svg {
    animation: yoOVDSqT_draw 5s linear forwards; }

@media all and (max-width: 575px) {
  .indx-banner .slider-banner {
    width: 84%; }
  .indx-banner .txt {
    width: 8%; } }

@media all and (min-width: 576px) {
  .indx-banner .slider-banner {
    width: 88%; }
  .indx-banner .txt {
    width: 7%; } }

.indx .prod-cat a {
  display: block; }

@media all and (max-width: 990px) {
  .indx .prod-cat .imgbx img.show-pc {
    display: none; } }

@media all and (min-width: 991px) {
  .indx .prod-cat .imgbx img.show-mo {
    display: none; } }

@media all and (max-width: 575px) {
  .indx .prod-cat .col-3 > div {
    margin-bottom: 20px; } }

@media all and (min-width: 576px) and (max-width: 990px) {
  .indx .prod-cat .col-3 {
    max-width: 480px;
    width: 90%;
    margin: 0 auto; }
    .indx .prod-cat .col-3 div {
      margin-bottom: 24px; } }

@media all and (min-width: 991px) {
  .indx .prod-cat .col-3 {
    display: flex; }
    .indx .prod-cat .col-3 div {
      flex: 0 0 calc((100% - 24px) / 3); }
    .indx .prod-cat .col-3 div:nth-child(3n+2) {
      margin-right: 12px;
      margin-left: 12px; } }

@media all and (min-width: 769px) {
  .indx .prod-cat .col-3 > div {
    transform: scale(1);
    transition: transform 0.4s cubic-bezier(0.26, 0.005, 0, 1.035); }
    .indx .prod-cat .col-3 > div:hover {
      transform: scale(1.1); } }

.indx .prod-cat .bg1 {
  position: absolute;
  left: 0; }
  @media all and (max-width: 575px) {
    .indx .prod-cat .bg1 {
      height: 84%;
      bottom: -13%; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .indx .prod-cat .bg1 {
      height: 84%;
      bottom: -6vw; } }
  @media all and (min-width: 991px) {
    .indx .prod-cat .bg1 {
      height: 20vw;
      min-height: 280px;
      bottom: -6vw; } }

.indx .prod-cat .bg2 {
  position: absolute;
  right: 0; }
  @media all and (min-width: 576px) {
    .indx .prod-cat .bg2 {
      height: 276px;
      top: 8vw; } }

@media all and (max-width: 575px) {
  .indx .prod-cat .bxname {
    position: absolute;
    top: -30px;
    left: 52%; } }

@media all and (min-width: 576px) {
  .indx .prod-cat .bxname {
    position: absolute;
    top: calc(8vw - 0.85em);
    left: 35.5vw; } }

@media all and (max-width: 575px) {
  .indx .prod-cat {
    margin-top: 130px;
    margin-bottom: 150px; } }

@media all and (min-width: 576px) and (max-width: 990px) {
  .indx .prod-cat {
    margin-top: 18vw;
    margin-bottom: 19vw; } }

@media all and (min-width: 991px) {
  .indx .prod-cat {
    margin-top: 14vw;
    margin-bottom: 15vw; } }

.indx .news .bg1 {
  z-index: -1; }
  @media all and (max-width: 575px) {
    .indx .news .bg1 {
      height: 50%;
      position: absolute;
      top: 40px;
      left: 0; } }
  @media all and (min-width: 576px) and (max-width: 768px) {
    .indx .news .bg1 {
      height: 80%;
      position: absolute;
      top: 67px;
      left: 50%;
      transform: translateX(-50%); } }
  @media all and (min-width: 769px) and (max-width: 990px) {
    .indx .news .bg1 {
      height: 80%;
      position: absolute;
      top: 80px;
      left: -24px; } }
  @media all and (min-width: 991px) {
    .indx .news .bg1 {
      height: 450px;
      position: absolute;
      top: 80px;
      left: -5%; } }

.indx .news .bg3 {
  height: 80%;
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0; }
  @media all and (max-width: 575px) {
    .indx .news .bg3 .mimg {
      width: 60%;
      position: absolute;
      bottom: 46px;
      left: 62%; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .indx .news .bg3 .mimg {
      width: 50%; } }
  @media all and (min-width: 576px) {
    .indx .news .bg3 .mimg {
      position: absolute;
      bottom: 120px;
      left: 70%; } }

.indx .news .bxname {
  z-index: -1; }
  @media all and (max-width: 575px) {
    .indx .news .bxname {
      position: absolute;
      top: -30px;
      left: 52%; } }
  @media all and (min-width: 576px) {
    .indx .news .bxname {
      position: absolute;
      top: calc(80px - 0.85em);
      right: 0; } }

.indx .news .tagbtn a {
  color: white; }

.indx .news .tagbtn a:after {
  background-color: white; }

@media all and (max-width: 575px) {
  .indx .news {
    padding-bottom: 70px;
    margin-bottom: 120px; }
    .indx .news .tagbtn {
      margin-top: 0px; } }

@media all and (min-width: 576px) and (max-width: 990px) {
  .indx .news {
    margin-bottom: 25vw;
    padding-bottom: 13vw; } }

@media all and (min-width: 991px) {
  .indx .news {
    margin-bottom: 18vw;
    padding-bottom: 4vw; }
    .indx .news .tagbtn {
      margin-bottom: 30px;
      margin-top: 60px; } }

@media all and (min-width: 576px) and (max-width: 768px) {
  .indx .news .tagbtn {
    max-width: 100vw;
    margin-left: calc(-50vw + 260px); } }

@media all and (min-width: 769px) and (max-width: 990px) {
  .indx .news .tagbtn {
    max-width: 80vw;
    margin-left: calc(-40vw + 360px); }
  .indx .news .archive {
    display: block; }
    .indx .news .archive .items {
      width: 400px;
      margin: 0 auto 60px !important; } }

.indx-brand {
  position: relative; }
  .indx-brand .bg2 {
    position: absolute;
    right: 0; }
    @media all and (max-width: 575px) {
      .indx-brand .bg2 {
        height: calc(100% + 20px);
        top: -20px; } }
    @media all and (min-width: 576px) {
      .indx-brand .bg2 {
        height: calc(100% + 35px);
        top: -35px; } }
  @media all and (max-width: 575px) {
    .indx-brand .bxname {
      position: absolute;
      top: calc(-20px - 0.8em);
      left: 52%; } }
  @media all and (min-width: 576px) {
    .indx-brand .bxname {
      position: absolute;
      top: calc(-35px - 0.8em);
      left: 51vw; } }
  .indx-brand .slick-slide > div > div {
    height: 100%; }
  .indx-brand .slick-btn .btn-slick-next,
  .indx-brand .slick-btn .btn-slick-prev {
    background-color: white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    @media all and (max-width: 575px) {
      .indx-brand .slick-btn .btn-slick-next,
      .indx-brand .slick-btn .btn-slick-prev {
        width: 50px;
        height: 50px; } }
    @media all and (min-width: 576px) {
      .indx-brand .slick-btn .btn-slick-next,
      .indx-brand .slick-btn .btn-slick-prev {
        width: 70px;
        height: 70px; } }
  .indx-brand .slick-btn span {
    width: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media all and (max-width: 1439px) {
    .indx-brand .slick-btn .btn-slick-prev {
      left: 50px; }
    .indx-brand .slick-btn .btn-slick-next {
      right: 50px; } }
  @media all and (min-width: 1440px) {
    .indx-brand .slick-btn .btn-slick-prev {
      left: calc((100vw - 1200px - 35px)/2); }
    .indx-brand .slick-btn .btn-slick-next {
      right: calc((100vw - 1200px - 70px)/2); } }
  @media all and (max-width: 575px) {
    .indx-brand {
      padding-bottom: 60px; }
      .indx-brand .titlebx {
        margin-bottom: 1em; } }
  @media all and (min-width: 576px) {
    .indx-brand {
      padding-bottom: 100px; }
      .indx-brand .titlebx {
        margin-bottom: 10px; } }

.indx .brands .items {
  display: block; }

@media all and (max-width: 575px) {
  .indx .brands.archive .stitle {
    font-size: 13px; }
  .indx .brands.archive .mtitle {
    font-size: 18px; }
  .indx .brands.archive .mtxt {
    padding: 16px; } }

@media all and (max-width: 768px) {
  .indx .brands .slick-slide {
    padding: 10px 15px; } }

@media all and (min-width: 769px) and (max-width: 990px) {
  .indx .brands .slick-slide {
    padding: 40px 20px; } }

@media all and (min-width: 991px) {
  .indx .brands .slick-list {
    padding-left: calc((100vw - 1200px) / 2); }
  .indx .brands .slick-slide {
    padding: 40px 20px; }
  .indx .brands .items {
    width: calc((1168px - 80px) / 3);
    display: block; } }

.circle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60px; }

@keyframes yoOVDSqT_draw {
  100% {
    stroke-dashoffset: 0; } }

.about .ylist:nth-child(odd) .items {
  background-color: white; }

.about .ylist:nth-child(even) .items {
  background-color: #e7f7ff; }

.about .items {
  display: flex; }

.about .myear {
  color: #101010;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em;
  font-family: "Lora", serif;
  text-align: center;
  position: relative; }
  @media all and (max-width: 575px) {
    .about .myear {
      font-size: 1.125rem; } }
  @media all and (min-width: 576px) {
    .about .myear {
      font-size: 1.5rem; } }
  .about .myear:before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #009fe3;
    position: absolute;
    top: 0;
    right: 0; }
  .about .myear::after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #009fe3;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
  @media all and (max-width: 575px) {
    .about .myear {
      flex: 0 0 80px; }
      .about .myear:after {
        width: 5px;
        height: 5px;
        position: absolute;
        top: 22px;
        right: -2px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .about .myear {
      flex: 0 0 100px; }
      .about .myear:after {
        width: 9px;
        height: 9px;
        position: absolute;
        top: 24px;
        right: -4px; } }
  @media all and (min-width: 991px) {
    .about .myear {
      flex: 0 0 180px; }
      .about .myear:after {
        width: 9px;
        height: 9px;
        position: absolute;
        top: 24px;
        right: -4px; } }

.about .mmonth {
  color: #009fe3;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em;
  text-align: right; }
  @media all and (max-width: 575px) {
    .about .mmonth {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .about .mmonth {
      font-size: 1rem; } }
  @media all and (max-width: 575px) {
    .about .mmonth {
      flex: 0 0 40px; } }
  @media all and (min-width: 576px) {
    .about .mmonth {
      flex: 0 0 60px; } }

.about .mtext {
  flex: 1; }
  @media all and (min-width: 991px) {
    .about .mtext {
      display: flex; }
      .about .mtext .editor_inner {
        flex: 0 0 75%;
        padding: 0 80px 0 40px; }
      .about .mtext .mtag {
        flex: 1; } }

@media all and (max-width: 575px) {
  .about .editor_inner {
    font-size: 0.875rem; } }

@media all and (min-width: 576px) {
  .about .editor_inner {
    font-size: 1rem; } }

@media all and (min-width: 769px) and (max-width: 990px) {
  .about .editor_inner.show-mo {
    display: block; }
  .about .editor_inner.show-pc {
    display: none; } }

.about .mtag {
  color: white;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .about .mtag {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .about .mtag {
      font-size: 1rem; } }
  .about .mtag span {
    display: inline-block;
    padding: .4em 0.4em 0.3em;
    background-color: #009fe3; }
  @media all and (max-width: 575px) {
    .about .mtag {
      margin-top: 8px; }
      .about .mtag span {
        margin-top: 6px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .about .mtag {
      margin-top: 16px; }
      .about .mtag span {
        margin-top: 12px; } }
  @media all and (min-width: 991px) {
    .about .mtag {
      margin-top: 4px; }
      .about .mtag i:not(:last-child) {
        display: block;
        margin-bottom: 14px; } }

.about .ylist:last-child .items:last-child .myear:before {
  height: 24px; }

.about .ylist:first-child .myear:before {
  top: 24px; }

@media all and (max-width: 575px) {
  .about .row.pd0 {
    padding-left: 0px;
    padding-right: 0px; }
  .about .myear {
    padding-top: 16px; }
  .about .mmonth {
    padding-top: 18px; }
  .about .mtext {
    padding: 12px 20px 16px; } }

@media all and (min-width: 576px) and (max-width: 990px) {
  .about .row.pd0 {
    padding-left: 0px;
    padding-right: 0px; }
  .about .myear {
    padding: 18px 0; }
  .about .mmonth {
    padding: 22px 0; }
  .about .mtext {
    padding: 16px 24px; } }

@media all and (min-width: 991px) {
  .about .myear {
    padding: 18px 0;
    padding-left: 3%; }
  .about .mmonth {
    padding: 22px 0; }
  .about .mtext {
    padding: 16px 0; } }

.brands .archive .bgcover, .brands.archive .bgcover {
  padding-bottom: 71.11%; }

.brands .archive .mimg, .brands.archive .mimg {
  background-color: white; }

.brands .archive .mtxt, .brands.archive .mtxt {
  width: 100%; }
  @media all and (max-width: 575px) {
    .brands .archive .mtxt, .brands.archive .mtxt {
      padding: 30px 20px; } }
  @media all and (min-width: 576px) {
    .brands .archive .mtxt, .brands.archive .mtxt {
      padding: 30px 20px 24px; } }

.brands .archive .stitle, .brands.archive .stitle {
  color: #9a9a9a;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .brands .archive .stitle, .brands.archive .stitle {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .brands .archive .stitle, .brands.archive .stitle {
      font-size: 1rem; } }

.brands .archive .mtitle, .brands.archive .mtitle {
  color: #009fe3;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.05em;
  margin: .2em 0 0.8em; }
  @media all and (max-width: 575px) {
    .brands .archive .mtitle, .brands.archive .mtitle {
      font-size: 1.1875rem; } }
  @media all and (min-width: 576px) {
    .brands .archive .mtitle, .brands.archive .mtitle {
      font-size: 1.375rem; } }

.brands .archive .stext, .brands.archive .stext {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.8;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .brands .archive .stext, .brands.archive .stext {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .brands .archive .stext, .brands.archive .stext {
      font-size: 1rem; } }

.brands .archive .items, .brands.archive .items {
  background-color: #f5f4f4; }
  @media all and (max-width: 768px) {
    .brands .archive .items, .brands.archive .items {
      box-shadow: 0 5px 10px 0 rgba(160, 160, 160, 0.3); } }
  @media all and (min-width: 769px) {
    .brands .archive .items, .brands.archive .items {
      box-shadow: 0 5px 10px 0 rgba(160, 160, 160, 0.3); } }
  .brands .archive .items .bgcover, .brands.archive .items .bgcover {
    transform: scale(1);
    transition: transform 0.6s cubic-bezier(0.26, 0.005, 0, 1.035); }
  @media all and (min-width: 769px) {
    .brands .archive .items:hover, .brands.archive .items:hover {
      transform: scale(1.1); }
      .brands .archive .items:hover .bgcover, .brands.archive .items:hover .bgcover {
        transform: scale(0.92); } }

@media all and (max-width: 768px) {
  .brands .archive .items {
    margin-bottom: 30px; } }

@media all and (min-width: 769px) and (max-width: 990px) {
  .brands .archive .items {
    flex: 0 0 calc((100% - 40px) / 2);
    margin-bottom: 45px; }
    .brands .archive .items:nth-child(odd) {
      margin-right: 20px; }
    .brands .archive .items:nth-child(even) {
      margin-left: 20px; } }

@media all and (min-width: 991px) {
  .brands .archive .items {
    flex: 0 0 calc((100% - 84px) / 3);
    margin-bottom: 60px; }
    .brands .archive .items:nth-child(3n+2) {
      margin-right: 42px;
      margin-left: 42px; } }

@media all and (min-width: 576px) {
  .brands .page {
    margin-top: 35px; } }

.news .tagbtn {
  margin-bottom: 40px; }
  .news .tagbtn a {
    color: #009fe3;
    font-weight: 400;
    line-height: 1.38;
    letter-spacing: 0.05em;
    display: inline-block;
    position: relative;
    text-align: center;
    opacity: 0.7; }
    @media all and (max-width: 575px) {
      .news .tagbtn a {
        font-size: 0.8125rem; } }
    @media all and (min-width: 576px) {
      .news .tagbtn a {
        font-size: 1.375rem; } }
    @media all and (max-width: 575px) {
      .news .tagbtn a {
        padding: 1em 0.2em; }
        .news .tagbtn a.lh2 {
          padding: 0.2em 0.2em; }
        .news .tagbtn a i {
          display: block; } }
    @media all and (min-width: 576px) and (max-width: 990px) {
      .news .tagbtn a {
        padding: 1.5em 0.8em;
        font-size: 1.125rem;
        margin-right: 1em; }
        .news .tagbtn a.lh2 {
          padding: 0.8em; }
        .news .tagbtn a i {
          display: block; } }
    @media all and (min-width: 991px) {
      .news .tagbtn a {
        padding: .8em;
        margin-right: 0.5em; } }
  .news .tagbtn a:after {
    bottom: 0; }
    @media all and (max-width: 575px) {
      .news .tagbtn a:after {
        height: 2px; } }
    @media all and (min-width: 576px) {
      .news .tagbtn a:after {
        height: 5px; } }
  .news .tagbtn a.active {
    opacity: 1; }
    .news .tagbtn a.active:after {
      width: 100%; }
  @media all and (min-width: 769px) {
    .news .tagbtn a:hover {
      opacity: 1; }
      .news .tagbtn a:hover:after {
        width: 100%; } }
  @media all and (max-width: 575px) {
    .news .tagbtn {
      margin-top: -30px;
      display: flex;
      justify-content: space-around;
      align-items: center; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .news .tagbtn {
      display: flex;
      align-items: center;
      padding: 0;
      max-width: calc(100% - 48px); } }

.news .detail .date-tag, .news .archive .date-tag {
  color: #9e9e9e;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center; }
  @media all and (max-width: 575px) {
    .news .detail .date-tag, .news .archive .date-tag {
      font-size: 0.75rem; } }
  @media all and (min-width: 576px) {
    .news .detail .date-tag, .news .archive .date-tag {
      font-size: 1rem; } }

.news .detail .mtag, .news .archive .mtag {
  border: 1px solid #9e9e9e;
  margin-left: 1em;
  padding: 0px 10px 0; }
  @media all and (max-width: 575px) {
    .news .detail .mtag, .news .archive .mtag {
      padding-top: 3px; } }
  @media all and (min-width: 576px) {
    .news .detail .mtag, .news .archive .mtag {
      padding-top: 2px; } }

.news .detail .mtitle, .news .archive .mtitle {
  color: #009fe3;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.05em;
  margin: 0.5em 0; }
  @media all and (max-width: 575px) {
    .news .detail .mtitle, .news .archive .mtitle {
      font-size: 0.9375rem; } }
  @media all and (min-width: 576px) {
    .news .detail .mtitle, .news .archive .mtitle {
      font-size: 1.375rem; } }

@media all and (min-width: 576px) {
  .news .detail .page, .news .archive .page {
    margin-top: 90px; } }

.news .archive .bgcover {
  padding-bottom: 62.5%;
  background-image: url(../images/news_img.jpg); }

.news .archive .mimg {
  background-color: #e7f7ff; }

.news .archive .mtxt {
  position: relative;
  width: 100%; }
  @media all and (max-width: 575px) {
    .news .archive .mtxt {
      padding: 16px 50px 24px 20px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .news .archive .mtxt {
      padding: 30px 0px 24px 30px; }
      .news .archive .mtxt .mtitle, .news .archive .mtxt .stext {
        padding-right: 80px; } }
  @media all and (min-width: 991px) {
    .news .archive .mtxt {
      padding: 30px 150px 24px 32px; } }

.news .archive .stext {
  color: #101010;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .news .archive .stext {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .news .archive .stext {
      font-size: 1rem; } }

.news .archive .rtbn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
  .news .archive .rtbn .st0 {
    fill: #101010; }
  .news .archive .rtbn .st1 {
    stroke: #101010; }
  .news .archive .rtbn .up {
    display: none; }
  @media all and (max-width: 575px) {
    .news .archive .rtbn {
      width: 20px;
      right: 20px; } }
  @media all and (min-width: 576px) {
    .news .archive .rtbn {
      width: 40px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .news .archive .rtbn {
      right: 30px; } }
  @media all and (min-width: 991px) {
    .news .archive .rtbn {
      right: 42px; } }

.news .archive .items {
  background-color: white;
  margin-bottom: 16px;
  box-shadow: 0px 10px 30px 0 rgba(160, 160, 160, 0.15); }

@media all and (min-width: 769px) {
  .news .archive .mdate, .news .archive .stext, .news .archive .mtag {
    transition: all 0.6s; }
  .news .archive a:hover .mdate, .news .archive a:hover .stext {
    color: #009fe3; }
  .news .archive a:hover .mtag {
    color: white;
    background-color: #009fe3;
    border-color: #009fe3; }
  .news .archive a:hover .rtbn .up {
    display: block;
    position: absolute;
    top: 0;
    left: 0px; }
  .news .archive a:hover .rtbn .st0 {
    fill: #009fe3; }
  .news .archive a:hover .rtbn .st1 {
    animation: fJOOgbfF_draw linear 0.6s forwards;
    stroke: #009fe3;
    stroke-dasharray: 483 485;
    stroke-dashoffset: 485; } }

@media all and (min-width: 769px) and (max-width: 990px) {
  .news .archive {
    display: flex;
    padding: 0;
    max-width: calc(100% - 48px); }
    .news .archive .items {
      flex: 0 0 calc((100% - 40px) / 2); }
      .news .archive .items:nth-child(odd) {
        margin-right: 20px; }
      .news .archive .items:nth-child(even) {
        margin-left: 20px; }
    .news .archive .rtbn {
      margin-top: 30px; } }

@media all and (min-width: 991px) {
  .news .archive a {
    display: flex; }
  .news .archive .mimg {
    width: 320px;
    flex: 0 0 320px; }
  .news .archive .mtitle {
    height: 1.6em;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* 第幾行要顯示點點點 */
    -webkit-box-orient: vertical; }
  .news .archive .stext {
    height: 3.5em;
    line-height: 1.75;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 第幾行要顯示點點點 */
    -webkit-box-orient: vertical; } }

@media all and (max-width: 768px) {
  .news .detail .editor_inner {
    padding: 40px 0 32px;
    border-top: 1px solid #009fe3; } }

@media all and (min-width: 769px) {
  .news .detail .editor_inner {
    padding: 32px 0 64px;
    border-top: 2px solid #009fe3; } }

.product .fresult {
  margin-bottom: 1.5em; }
  .product .fresult span {
    color: #777;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.05em;
    display: inline-block;
    margin-right: 30px; }
    @media all and (max-width: 575px) {
      .product .fresult span {
        font-size: 1rem; } }
    @media all and (min-width: 576px) {
      .product .fresult span {
        font-size: 1.125rem; } }
  .product .fresult span:before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: #009fe3;
    margin-right: 16px;
    vertical-align: -2px; }
  .product .fresult i {
    color: #777;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.05em; }
    @media all and (max-width: 575px) {
      .product .fresult i {
        font-size: 1rem; } }
    @media all and (min-width: 576px) {
      .product .fresult i {
        font-size: 1.125rem; } }
  .product .fresult i::before {
    content: " | ";
    margin-right: 16px; }
  @media all and (max-width: 990px) {
    .product .fresult div {
      padding-left: 2.3em;
      margin-top: 14px; } }
  @media all and (max-width: 990px) and (max-width: 575px) {
    .product .fresult div {
      font-size: 1.25rem; } }
  @media all and (max-width: 990px) and (min-width: 576px) {
    .product .fresult div {
      font-size: 1.375rem; } }
  @media all and (min-width: 991px) {
    .product .fresult {
      display: flex; }
      .product .fresult h5 {
        flex: 0 0 150px; }
      .product .fresult div {
        padding-left: 60px;
        flex: 1; }
      .product .fresult span {
        margin-top: 8px; } }

.product h5 {
  color: #009fe3;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .product h5 {
      font-size: 1.25rem; } }
  @media all and (min-width: 576px) {
    .product h5 {
      font-size: 1.375rem; } }
  .product h5 img {
    width: 1.5em;
    margin-right: 0.8em;
    vertical-align: -0.1em; }

.product #searchbox {
  width: 100%;
  height: 1px; }
  @media all and (max-width: 768px) {
    .product #searchbox {
      transform: translateY(-130px); } }
  @media all and (min-width: 769px) and (max-width: 990px) {
    .product #searchbox {
      transform: translateY(-220px); } }
  @media all and (min-width: 991px) {
    .product #searchbox {
      transform: translateY(-180px); } }

.product .row .formtable {
  background-color: white;
  box-shadow: 0 0 30px 0 rgba(160, 160, 160, 0.3);
  color: #777;
  font-weight: 300;
  line-height: 1.85;
  letter-spacing: 0.05em;
  font-size: 1rem; }
  .product .row .formtable .mtitle {
    color: #101010;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.05em;
    border-bottom: 3px solid #e7f7ff;
    padding: 0.8em 0;
    margin-bottom: 1em; }
    @media all and (max-width: 575px) {
      .product .row .formtable .mtitle {
        font-size: 1.1875rem; } }
    @media all and (min-width: 576px) {
      .product .row .formtable .mtitle {
        font-size: 1.25rem; } }
    @media all and (max-width: 575px) {
      .product .row .formtable .mtitle {
        border-bottom-width: 2px;
        margin-bottom: 0.8em; } }
  @media all and (max-width: 575px) {
    .product .row .formtable .check_radi {
      padding: 4px 1em 4px 2em;
      margin-bottom: 0.4em; } }
  @media all and (min-width: 576px) {
    .product .row .formtable .check_radi {
      padding-left: 2em;
      margin-bottom: 0.2em; } }
  .product .row .formtable .catbtn {
    color: white;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.05em;
    cursor: pointer; }
    .product .row .formtable .catbtn i {
      width: 0.8em;
      height: 0.8em;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
    .product .row .formtable .catbtn i:before, .product .row .formtable .catbtn i:after {
      content: "";
      background-color: white; }
    .product .row .formtable .catbtn i:before {
      width: 2px;
      height: 0.8em;
      position: absolute;
      top: 0;
      left: calc(0.4em - 1px); }
    .product .row .formtable .catbtn i:after {
      width: 0.8em;
      height: 2px;
      position: absolute;
      top: calc(0.4em - 1px);
      left: 0;
      opacity: 1;
      transition: opacity 0.6s; }
    .product .row .formtable .catbtn.is-open i:before {
      opacity: 0; }
    @media all and (max-width: 575px) {
      .product .row .formtable .catbtn {
        font-size: 1.125rem; } }
    @media all and (min-width: 576px) {
      .product .row .formtable .catbtn {
        font-size: 1.25rem; } }
    @media all and (max-width: 575px) {
      .product .row .formtable .catbtn {
        padding: 14px 16px;
        margin: 24px 0; } }
    @media all and (min-width: 576px) and (max-width: 990px) {
      .product .row .formtable .catbtn {
        width: 50%;
        padding: 14px 16px;
        margin: 24px 0; } }
    @media all and (min-width: 991px) {
      .product .row .formtable .catbtn {
        max-width: 315px;
        flex: 0 0 45%;
        padding: 24px 30px; } }
  .product .row .formtable .searchbx {
    position: relative; }
    .product .row .formtable .searchbx input {
      background-color: white;
      border-bottom: 1px solid #ccc;
      padding: 0.7em 0; }
    @media all and (min-width: 769px) {
      .product .row .formtable .searchbx {
        max-width: 310px;
        flex: 0 0 45%; } }
  .product .row .formtable .btngroup a {
    text-align: center;
    display: inline-block; }
    @media all and (max-width: 575px) {
      .product .row .formtable .btngroup a {
        font-size: 0.9375rem; } }
    @media all and (min-width: 576px) {
      .product .row .formtable .btngroup a {
        font-size: 1.125rem; } }
  .product .row .formtable .btngroup .sedbtn {
    color: white;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.05em; }
  .product .row .formtable .btngroup .clerbtn {
    color: #009fe3;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.05em;
    background-color: white;
    border: 1px solid #009fe3; }
  @media all and (max-width: 768px) {
    .product .row .formtable .btngroup {
      display: flex;
      justify-content: space-between;
      margin: 30px 0; }
      .product .row .formtable .btngroup a {
        flex: 0 0 47%;
        padding: 0.5em 0 0.4em; } }
  @media all and (min-width: 769px) {
    .product .row .formtable .btngroup {
      text-align: right;
      margin-top: 48px;
      margin-bottom: 5px; }
      .product .row .formtable .btngroup a {
        width: 176px;
        margin-left: 20px;
        padding: 0.7em 0 0.6em; } }
  .product .row .formtable .w100 {
    flex: 0 0 100%; }
  .product .row .formtable .chexbx {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start; }
    @media all and (max-width: 575px) {
      .product .row .formtable .chexbx {
        margin-bottom: 16px;
        line-height: 1.5; }
        .product .row .formtable .chexbx.v1 > div {
          flex: 0 0 100%; }
        .product .row .formtable .chexbx.v2 > div:not(.w100) {
          flex: 0 0 50%; } }
    @media all and (min-width: 576px) and (max-width: 990px) {
      .product .row .formtable .chexbx > div:not(.w100) {
        flex: 0 0 33.33%; } }
    @media all and (min-width: 991px) {
      .product .row .formtable .chexbx {
        margin-top: 40px; }
        .product .row .formtable .chexbx.v1 {
          flex: 0 0 20%; }
        .product .row .formtable .chexbx.v2 {
          flex: 0 0 76%; }
        .product .row .formtable .chexbx.v2 > div:not(.w100) {
          flex: 0 0 25%; } }
  @media all and (min-width: 769px) {
    .product .row .formtable .keybx {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .product .row .formtable .keybx .searchbx {
        order: 2; }
      .product .row .formtable .keybx .catbtn {
        order: 1; } }
  @media all and (max-width: 575px) {
    .product .row .formtable {
      padding: 20px 24px;
      margin-bottom: 60px; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .product .row .formtable {
      padding: 30px 30px 30px;
      margin-bottom: 50px; } }
  @media all and (min-width: 991px) {
    .product .row .formtable {
      padding: 40px 60px;
      margin-bottom: 70px; }
      .product .row .formtable .hdtable {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }

.product .row-table {
  color: #101010;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.05em; }
  @media all and (max-width: 575px) {
    .product .row-table {
      font-size: 0.9375rem; } }
  @media all and (min-width: 576px) {
    .product .row-table {
      font-size: 1rem; } }
  @media all and (max-width: 990px) {
    .product .row-table .list {
      margin-bottom: 40px; }
    .product .row-table .list > div {
      display: table;
      width: 100%;
      margin-bottom: 4px; }
    .product .row-table .list > div:nth-child(odd) {
      background-color: #e9f8ff; }
    .product .row-table .list span {
      vertical-align: middle;
      display: table-cell;
      padding: 12px 20px; }
    .product .row-table .list > div:before {
      background-color: #009fe3;
      color: white;
      text-align: center;
      vertical-align: middle;
      display: table-cell; }
    .product .row-table .list > div:nth-child(1):before {
      content: "產品名稱"; }
    .product .row-table .list > div:nth-child(2):before {
      content: "INCI NAME"; }
    .product .row-table .list > div:nth-child(3):before {
      content: "效能"; }
    .product .row-table .list > div:nth-child(4):before {
      content: "備註"; }
    .product .row-table .list > div:nth-child(5):before {
      content: "包裝"; }
    .product .row-table .list.mtitle {
      display: none; } }
  @media all and (max-width: 575px) {
    .product .row-table {
      margin-bottom: 35px; }
      .product .row-table .list span {
        padding: 3px 14px; }
      .product .row-table .list > div:before {
        padding: 3px;
        width: 7em; } }
  @media all and (min-width: 576px) and (max-width: 990px) {
    .product .row-table {
      margin-bottom: 70px; }
      .product .row-table .list span {
        padding: 12px 20px; }
      .product .row-table .list > div:before {
        padding: 10px;
        width: 10em; } }
  @media all and (min-width: 991px) {
    .product .row-table {
      margin-bottom: 70px; }
      .product .row-table .list {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d2d2d2; }
      .product .row-table .list > div {
        border-left: 2px solid white;
        border-right: 2px solid white; }
      .product .row-table .list > div:nth-child(1) {
        flex: 0 0 20%;
        color: #009fe3; }
        .product .row-table .list > div:nth-child(1) span {
          width: 10em;
          display: block; }
      .product .row-table .list > div:nth-child(2) {
        flex: 0 0 20%; }
      .product .row-table .list > div:nth-child(3) {
        flex: 0 0 37%; }
      .product .row-table .list > div:nth-child(4) {
        flex: 0 0 15%; }
      .product .row-table .list > div:nth-child(5) {
        flex: 0 0 8%;
        text-align: center; }
      .product .row-table .list > div:nth-child(1),
      .product .row-table .list > div:nth-child(2) {
        padding: 24px 16px; }
      .product .row-table .list > div:nth-child(3),
      .product .row-table .list > div:nth-child(4) {
        padding: 24px 16px; }
      .product .row-table .list.mtitle > div {
        background-color: #009fe3;
        color: white;
        text-align: center;
        padding: 9px; } }
