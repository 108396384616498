//lightcase
#lightcase-case{
    padding:0 15px;
    iframe{
        max-width:calc(100vw - 30px) !important;
    }
}
#lightcase-overlay{
    background-color: rgba($color: #000000, $alpha: 0.8);
}
a[class*="lightcase-icon-"].lightcase-icon-close{
    // background: url(../svg/lighcase-close.svg) no-repeat center;    
    @include mediaquery(null,$sm){
        width:20px;
        height: 20px;
        background-size: 20px;
    }
    @include mediaquery($sm,null){
        width:34px;
        height: 34px;
        background-size: 34px;
    }
    &:before{
        display: none;
    }
}

//slick next prev btn and dots
.slickbox{
    position: relative;
    //overwirte dots
    .slick-dots{
        //位置
        width:auto;     
        @include position(absolute,$left:50%);
        transform: translateX(-50%);
        z-index: 10;
        li{    
            margin:0;
            width:auto;
            height: auto !important;
            transition: opacity 0.6s;
            @include mediaquery(null,$md){
                padding:10px 5px;
            }
            @include mediaquery($md,null){              
                padding:0 16px 1.2vw 16px;
            }
        }     
        span{
            display: inline-block;
            transition: background 0.6s;  
            position: relative;
            @include border();
            @include mediaquery(null,$md){              
                width:12px;
                height:12px;
            }
            @include mediaquery($md,null){         
                width:12px;
                height:12px;
            }
        }
    }
    
}

//overwrite plugin scroll
// .hiddendiv{
//     display: none;
// }
// .mCSB_inside > .mCSB_container{
//     margin-right: 24px;
// }
// .mCSB_scrollTools{
//     opacity: 1;
// }
// .mCSB_scrollTools .mCSB_draggerRail{
//     background-color: rgba($green,0.5);
//     width:2px;
// }
// .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
//     width:6px;
//     background-color:  rgba($green,0.5) !important;
//     opacity: 1 !important;
// }
// .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
//     background-color:  rgba($green,0.5);
// }
// .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar{
//     opacity: 1 !important;
// }