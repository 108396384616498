header{
    @include position(fixed,$top:0,$left:0);
    width:100%;   
    z-index: 101;     
    background-color: white;
    // box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.35);
    @include mediaquery(null,$md){    
        height: $headH-mobile;
        .title-bar{            
            background-color: white;
            @include position(absolute,$top:0,$left:0);
            z-index: 10;
            width:100%;
            height: $headH-mobile;    
        } 
        //menu影藏和展開的位置
        .navBox{       
            @include position(fixed,$top:0,$right:0px);           
            width:100%;           
            height:100%;
            background-color: rgba(white,1);  
            // -webkit-overflow-scrolling: touch;
            // overflow-y: scroll;
            display: none;
        }
    }    
    @include mediaquery($md,$lg){
        .title-bar{  
            width:100%;
            height: $headH-medium;
            position: relative;
        }
    }
    .menuicon{
        @include mediaquery(null,$md){
            right: $pdr-m;
        }
        // @include mediaquery($md,null){
        //     right: $pdr-body;
        // }
        @include mediaquery($md,null){
            display: none;
        }
        @include position(absolute, $top:50%);
        transform: translateY(-50%);
		z-index: 10;       
        .mpsrel{
            width:40px;
            height:40px;
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            @include position(absolute,$right:0%,$top:0);
            height: 1.5px;   
            width: 24px;   
            background:rgba(#b5b5b5,1);       
        }
        .bar{
            margin-top: 19px;
        }
        .bar:before{top: -8px;}
        .bar:after{top: 8px;}
    }
    //mobile meun open
    &.menu-expanded{
        .menuicon .bar{
            background:rgba(black,0);
            &:before{
                top:0px;
                transform:rotate(45deg);
                width: 28px;
            }
            &:after{
                top:0px;
                transform: rotate(-45deg);
                width: 28px;
            }
        }
    }
    .logo{
        @include position(absolute,$top:50%);
        transform: translateY(-50%);
        z-index: 10;
        transition: none;
        @include mediaquery(null,$md){
            left: $pdr-m;
            width:170px;
        }
        @include mediaquery($md,null){
            width:330px;
        }
        @include mediaquery($md,$lg){
            left:50%;
            transform: translate(-50%,-30%);
        }
        @include mediaquery($lg,$xxl){ 
            left: $pdr-m;
        }
        @include mediaquery($xxl,null){  
            left: 80px;
        }
    }    
    //mmenu
    ul.mmenu{  
        @include mediaquery(null,$md){
            width: 76%;
            margin: 108px auto 108px;
            padding-left:24px;
        }
        @include mediaquery($md,$lg){
            display: flex;
            align-items: center; 
            justify-content:center; 
            height: $headH-medium;
        }
        /*位置上下左右對齊*/
		@include mediaquery($lg,null){
            display: flex;
            justify-content:flex-end;
            align-items: center; 
            height: $headH-desk;
            padding:0 $pdr-m;
        }  
        @include mediaquery($xxl,null){  
            padding:0 80px;
        }
		> li{
            a{
                display: block;
                position: relative;
                @include font-color(#2e2e2e,300,1);
                @include font-size(pxToEm(18),pxToEm(16));
                transition: color 0.6s;
                @extend .line_hover;
            }
            a i{
                font-family: $fen;
                font-size: pxToEm(13);
                color:black;
                margin-left: 0.5em;
            }
            &.active a:after{
                width: 100%;
            }
            @include mediaquery(null,$md){
                display: block;
                flex:0 0 100%;
                margin-bottom: 40px;
                a{                    
                    border-left: 4px solid black;
                    padding:4px 24px;
                } 
            }
            @include mediaquery($md,null){            
                margin-left:24px;  
                i{
                    display: none;
                }
                a{
                    position: relative;
                    padding:0 8px;
                }
                a:hover{
                    color:$color1;
                }
                a:after{
                    height: 2px;
                } 
            } 
            @include mediaquery($lg,null){                               
                &.pos-pc{
                    position: relative;
                }
            }
        }  
    }//end mmenu  
    div.srhbtn{
        width: 60px;
        height: 40px;
        cursor: pointer;
        background: no-repeat url(../svg/search.svg) 31px 50% / 27px 27px;
        @include mediaquery(null,$md){
            display: none;
        }
        @include mediaquery($md,$lg){
            @include position(absolute,$right:20px,$bottom:16px);
        }
        @include mediaquery($lg,null){
            &::before{
                content:"";
                display: inline-block;
                width:2px;
                height: 40px;
                background-color: #999;
                margin-right: 30px;
                vertical-align: -6px;
            }
        }
    }
    div.closed{
        width: 40px;
        height: 40px;
        background-color: white;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.6s;
        &::before,&:after{
            content:"";
            display: block;
            width: 32px;
            height: 2px;            
            background-color: #707070;
            @include position(absolute,$top:20px,$left:5px);
        }
        &::before{
            transform: rotate(45deg);
        }
        &:after{
            transform: rotate(-45deg);
        }
        &.is-open{
            opacity: 1;
        }
        @include mediaquery(null,$md){
            display: none;
        }
        @include mediaquery($md,null){
            @include position(absolute,$right:-2px,$top:0px);
        }
    }
    nav .formtable{
        background-color: $color2;
        transition: top 0.4s cubic-bezier(0.260, 0.005, 0.000, 1.035);
        input{
            border-bottom: 1px solid #ccc;
            padding:0.7em 0;
        }
        .searchbx{
            position: relative;
        }
        @include mediaquery(null,$md){
            @include position(fixed,$top:430px,$left:0);
            padding:36px 60px;
            width: 100%;
        }
        @include mediaquery($md,null){            
            display: none;
            width: 480px;
            height: 100px;
            padding:24px 60px;
        }
        @include mediaquery($md,$lg){
            @include position(fixed,$top:$headH-medium2,$right:0);
        }
        @include mediaquery($lg,null){
            @include position(fixed,$top:$headH-desk,$right:0);
        }
    }
}
