.about{
    .ylist{
        &:nth-child(odd){
            .items{
                background-color: white;
            }
        }
        &:nth-child(even){
            .items{
                background-color: #e7f7ff;
            }
        }
    }
    .items{        
        display: flex;
    }
    .myear{
        @include font-color(#101010,400,1);
        @include font-size(pxToEm(18),pxToEm(24));
        font-family: $fen;
        text-align: center;
        position: relative;
        &:before{
            content:"";
            width:1px;
            height: 100%;
            background-color: $color1;
            @include position(absolute,$top:0,$right:0)
        }
        &::after{
            content:"";
            width: 10px;
            height: 10px;
            background-color: $color1;
            @include border();
        }
        @include mediaquery(null,$sm){
            flex:0 0 80px;
            &:after{
                width: 5px;
                height: 5px;
                @include position(absolute,$top:22px,$right:-2px);
            }
        }
        @include mediaquery($sm,$lg){
            flex:0 0 100px;
            &:after{
                width: 9px;
                height: 9px;
                @include position(absolute,$top:24px,$right:-4px);
            }
        }
        @include mediaquery($lg,null){
            flex:0 0 180px;
            &:after{
                width: 9px;
                height: 9px;
                @include position(absolute,$top:24px,$right:-4px);
            }
        }
    }
    .mmonth{
        @include font-color($color1,400,1);
        @include font-size(pxToEm(14),pxToEm(16));
        text-align: right;
        @include mediaquery(null,$sm){
            flex:0 0 40px;
        }
        @include mediaquery($sm,null){
            flex:0 0 60px;
        }
    }
    .mtext{
        flex:1;
        @include mediaquery(null,$sm){
            
        }
        @include mediaquery($lg,null){   
            display: flex;
            .editor_inner{                
                flex:0 0 75%;
                padding:0 80px 0 40px;
            }
            .mtag{
                flex:1;
            }
        }
    }
    .editor_inner{
        @include font-size(pxToEm(14),pxToEm(16));
        @include mediaquery($md,$lg){
            &.show-mo{
                display: block;
            }
            &.show-pc{
                display: none;
            }
        }
    }
    .mtag{
        @include font-color(white,400,1);
        @include font-size(pxToEm(14),pxToEm(16));
        span{
            display: inline-block;
            padding:.4em 0.4em 0.3em;
            background-color: $color1;
        }
        @include mediaquery(null,$sm){
            margin-top: 8px;
            span{
                margin-top: 6px;
            }
        }
        @include mediaquery($sm,$lg){
            margin-top: 16px;
            span{
                margin-top: 12px;
            }
        }
        @include mediaquery($lg,null){
            margin-top: 4px;
            i:not(:last-child){
                display: block;
                margin-bottom: 14px;
            }
        }
    }
    //line top and btm
    .ylist{
        &:last-child .items:last-child .myear:before{
            height: 24px;
        }
        &:first-child .myear:before{
            top:24px;
        }
    }
    @include mediaquery(null,$sm){
        .row.pd0{
            padding-left: 0px;
            padding-right: 0px;
        }
        .myear{
            padding-top: 16px;
        }
        .mmonth{
            padding-top: 18px;
        }
        .mtext{
            padding:12px $pdr-m 16px;
        }
    }
    @include mediaquery($sm,$lg){
        .row.pd0{
            padding-left: 0px;
            padding-right: 0px;
        }
        .myear{
            padding:18px 0 ;
        }
        .mmonth{
            padding:22px 0;
        }
        .mtext{
            padding: 16px 24px;
        }
    }
    @include mediaquery($lg,null){
        .myear{
            padding:18px 0 ;
            padding-left: 3%;
        }
        .mmonth{
            padding:22px 0;
        }
        .mtext{
            padding: 16px 0;
        }
    }
}