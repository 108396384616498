footer{
    background-color:white;
    position: relative;
    .f-info{
        position: relative;
        .logo{
            @include position(absolute,$left:0,$top:0);
            display: block;
        }
        .mtitle{
            @include font-color(black,400,1);
            @include font-size(pxToEm(13),pxToEm(18));
            width:100%;
            margin-bottom: 12px;
        }
        .bx{            
            @include font-size(pxToEm(12),pxToEm(16));
        }
        .bx i{
            @include font-color($color1,300,1.67);
            margin-right: 0.8em;
        }
        .bx a{
            @include font-color(#666,300,1.67);
        }        
        @include mediaquery(null,$md){     
            padding-left: 100px;
            padding-top: 0.5em;
        }
        @include mediaquery(null,$sm){        
            padding-left: 50px;
            .logo{
                width: 30px;
            }
        }
        @include mediaquery($md,null){     
            display: flex;
            flex-wrap: wrap;
            padding-left: 100px;
            width: 660px;
            .bx.mr{
                margin-right: 60px;
            }
        }
    }
    .f-menu{
        display: flex;
        a{            
            @include font-color(black,300,1);
            @include font-size(pxToEm(12),pxToEm(16));
        }
        @include mediaquery(null,$md){
            justify-content: space-between;
            margin: 28px auto;
            max-width: 500px;
        }
        @include mediaquery($md,null){
            width: 660px;
            margin-top: 30px;
            padding-left: 100px;
            a:hover{
                color:$color1;
            }
            a:not(:first-child){
                margin-left: 2.2em;
            }
        }
        @include mediaquery($lg,null){
            justify-content: flex-end;
            @include position(absolute,$right:20px,$top:70px);
        }
        @include mediaquery($xxl,null){
            @include position(absolute,$right:190px,$top:70px);
        }
    }
    .copyright{
        @include font-color(#9e9e9e,300,1.5);
        @include font-size(pxToEm(12),pxToEm(14));
        a,a:hover{
            color:#9e9e9e;
        }
        @include mediaquery(null,$sm){
            a{
                display: block;
            }
        }
        @include mediaquery(null,$md){            
            max-width: 500px;
            margin:0 auto;
        }
        @include mediaquery($md,null){ 
            width: 660px;           
            padding-left: 100px;
            margin-top: 24px;
        }
    }
    @include mediaquery(null,$md){       
        padding:24px $pdr-m 24px;
    }
    @include mediaquery($md,null){      
        padding:70px $pdr-m 40px;
    }
    @include mediaquery($md,$lg){   
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    @include mediaquery($xxl,null){      
        padding:70px 190px 40px 75px;
    }
}
.f-contact{
    .mtitle{
        @include font-color(white,400,1);
        @include font-size(pxToEm(22),pxToEm(30));
    }
    .stext{
        @include font-color(white,300,1.64);
        @include font-size(pxToEm(14),pxToEm(16));
        @include mediaquery(null,$md){
            max-width: 25em;
            margin: 1.5em auto 1em;
        }
        @include mediaquery($md,null){
            margin: 1em auto 1.5em;
        }
    }
    a[href^="tel:"]{
        @include font-color(white,700,40px);
        @include font-size(pxToEm(20),pxToEm(30));
        img{
            margin-right: 0.6em;
        }
    }
    a.ediform{
        @include font-color(white,400,40px);
        @include font-size(pxToEm(15),pxToEm(22));
        display: inline-block;
        background-color: #40d4e6;
        @include mediaquery(null,$sm){
            padding:.5em 2em 0;
            margin-top: 1.2em;
            img{
                margin-right: 0.7em;
                vertical-align: -5px;
            }
        }
        @include mediaquery($sm,null){            
            &:hover{
                background-color: rgba(#40d4e6,0.7);
            }
            padding:0.9em 2.5em;
            margin-top: 2em;
            img{
                margin-right: 1.5em;
                vertical-align: -10px;
            }
        }
    }
    div img{
        @include mediaquery(null,$sm){
            width: 25px;
        }
        @include mediaquery($sm,null){
            width: 40px;
        }
    }
    @include mediaquery(null,$sm){
        padding:48px 16px;
    }
    @include mediaquery($sm,null){
        padding:100px 40px 50px;
    }
    @include mediaquery(null,$md){
        background:no-repeat url(../images/f_contact_mo.jpg) center / cover;
    }
    @include mediaquery($md,null){
        background:no-repeat url(../images/f_contact_pc.jpg) center / cover;
    }
}
